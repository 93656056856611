
var CanvasKitInit = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(CanvasKitInit = {})  {

var t;t||(t=typeof CanvasKitInit !== 'undefined' ? CanvasKitInit : {});var aa,ba;t.ready=new Promise(function(a,b){aa=a;ba=b});
(function(a){a.Qd=a.Qd||[];a.Qd.push(function(){a.MakeSWCanvasSurface=function(b){var c=b;if("CANVAS"!==c.tagName&&(c=document.getElementById(b),!c))throw"Canvas with id "+b+" was not found";if(b=a.MakeSurface(c.width,c.height))b.Dd=c;return b};a.MakeCanvasSurface||(a.MakeCanvasSurface=a.MakeSWCanvasSurface);a.MakeSurface=function(b,c){var f={width:b,height:c,colorType:a.ColorType.RGBA_8888,alphaType:a.AlphaType.Unpremul,colorSpace:a.ColorSpace.SRGB},g=b*c*4,m=a._malloc(g);if(f=a.Surface._makeRasterDirect(f,
m,4*b))f.Dd=null,f.uf=b,f.rf=c,f.tf=g,f.Ue=m,f.getCanvas().clear(a.TRANSPARENT);return f};a.MakeRasterDirectSurface=function(b,c,f){return a.Surface._makeRasterDirect(b,c.byteOffset,f)};a.Surface.prototype.flush=function(b){this._flush();if(this.Dd){var c=new Uint8ClampedArray(a.HEAPU8.buffer,this.Ue,this.tf);c=new ImageData(c,this.uf,this.rf);b?this.Dd.getContext("2d").putImageData(c,0,0,b[0],b[1],b[2]-b[0],b[3]-b[1]):this.Dd.getContext("2d").putImageData(c,0,0)}};a.Surface.prototype.dispose=function(){this.Ue&&
a._free(this.Ue);this.delete()};a.currentContext=a.currentContext||function(){};a.setCurrentContext=a.setCurrentContext||function(){}})})(t);
(function(a){a.Qd=a.Qd||[];a.Qd.push(function(){function b(c,f,g){return c&&c.hasOwnProperty(f)?c[f]:g}a.GetWebGLContext=function(c,f){if(!c)throw"null canvas passed into makeWebGLContext";var g={alpha:b(f,"alpha",1),depth:b(f,"depth",1),stencil:b(f,"stencil",8),antialias:b(f,"antialias",0),premultipliedAlpha:b(f,"premultipliedAlpha",1),preserveDrawingBuffer:b(f,"preserveDrawingBuffer",0),preferLowPowerToHighPerformance:b(f,"preferLowPowerToHighPerformance",0),failIfMajorPerformanceCaveat:b(f,"failIfMajorPerformanceCaveat",
0),enableExtensionsByDefault:b(f,"enableExtensionsByDefault",1),explicitSwapControl:b(f,"explicitSwapControl",0),renderViaOffscreenBackBuffer:b(f,"renderViaOffscreenBackBuffer",0)};g.majorVersion=f&&f.majorVersion?f.majorVersion:"undefined"!==typeof WebGL2RenderingContext?2:1;if(g.explicitSwapControl)throw"explicitSwapControl is not supported";c=fa(c,g);if(!c)return 0;ia(c);return c};a.deleteContext=function(c){u===ja[c]&&(u=null);"object"==typeof JSEvents&&JSEvents.jg(ja[c].ze.canvas);ja[c]&&ja[c].ze.canvas&&
(ja[c].ze.canvas.df=void 0);ja[c]=null};a.MakeWebGLCanvasSurface=function(c,f,g){f=f||null;var m=c,q="undefined"!==typeof OffscreenCanvas&&m instanceof OffscreenCanvas;if(!("undefined"!==typeof HTMLCanvasElement&&m instanceof HTMLCanvasElement||q||(m=document.getElementById(c),m)))throw"Canvas with id "+c+" was not found";c=this.GetWebGLContext(m,g);if(!c||0>c)throw"failed to create webgl context: err "+c;g=this.MakeGrContext(c);f=this.MakeOnScreenGLSurface(g,m.width,m.height,f);if(!f)return f=m.cloneNode(!0),
m.parentNode.replaceChild(f,m),f.classList.add("ck-replaced"),a.MakeSWCanvasSurface(f);f.de=c;f.grContext=g;f.openGLversion=m.df.version;return f};a.MakeCanvasSurface=a.MakeWebGLCanvasSurface})})(t);
(function(a){function b(e,d,h,l,r){for(var z=0;z<e.length;z++)d[z*h+(z*r+l+h)%h]=e[z];return d}function c(e){for(var d=e*e,h=Array(d);d--;)h[d]=0===d%(e+1)?1:0;return h}function f(e){return e?e.constructor===Float32Array&&4===e.length:!1}function g(e){return(p(255*e[3])<<24|p(255*e[0])<<16|p(255*e[1])<<8|p(255*e[2])<<0)>>>0}function m(e){if(e instanceof Float32Array){for(var d=Math.floor(e.length/4),h=new Uint32Array(d),l=0;l<d;l++)h[l]=g(e.slice(4*l,4*(l+1)));return h}if(e instanceof Uint32Array)return e;
if(e instanceof Array&&e[0]instanceof Float32Array)return e.map(g)}function q(e){if(void 0===e)return 1;var d=parseFloat(e);return e&&-1!==e.indexOf("%")?d/100:d}function p(e){return Math.round(Math.max(0,Math.min(e||0,255)))}function x(e,d){d&&!d._ck&&a._free(e)}function v(e,d,h){if(!e||!e.length)return da;if(e._ck)return e.byteOffset;var l=a[d].BYTES_PER_ELEMENT;h||(h=a._malloc(e.length*l));a[d].set(e,h/l);return h}function G(e){var d={Ud:da,count:e.length,colorType:a.ColorType.RGBA_F32};if(e instanceof
Float32Array)d.Ud=v(e,"HEAPF32"),d.count=e.length/4;else if(e instanceof Uint32Array)d.Ud=v(e,"HEAPU32"),d.colorType=a.ColorType.RGBA_8888;else if(e instanceof Array){if(e&&e.length){for(var h=a._malloc(16*e.length),l=0,r=h/4,z=0;z<e.length;z++)for(var C=0;4>C;C++)a.HEAPF32[r+l]=e[z][C],l++;e=h}else e=da;d.Ud=e}else throw"Invalid argument to copyFlexibleColorArray, Not a color array "+typeof e;return d}function J(e){if(!e)return da;if(e.length){if(6===e.length||9===e.length)return v(e,"HEAPF32",Ha),
6===e.length&&a.HEAPF32.set(vd,6+Ha/4),Ha;if(16===e.length){var d=xb.toTypedArray();d[0]=e[0];d[1]=e[1];d[2]=e[3];d[3]=e[4];d[4]=e[5];d[5]=e[7];d[6]=e[12];d[7]=e[13];d[8]=e[15];return Ha}throw"invalid matrix size";}d=xb.toTypedArray();d[0]=e.m11;d[1]=e.m21;d[2]=e.m41;d[3]=e.m12;d[4]=e.m22;d[5]=e.m42;d[6]=e.m14;d[7]=e.m24;d[8]=e.m44;return Ha}function O(e){if(!e)return da;var d=Tb.toTypedArray();if(e.length){if(16!==e.length&&6!==e.length&&9!==e.length)throw"invalid matrix size";if(16===e.length)return v(e,
"HEAPF32",Ia);d.fill(0);d[0]=e[0];d[1]=e[1];d[3]=e[2];d[4]=e[3];d[5]=e[4];d[7]=e[5];d[12]=e[6];d[13]=e[7];d[15]=e[8];6===e.length&&(d[12]=0,d[13]=0,d[15]=1);return Ia}d[0]=e.m11;d[1]=e.m21;d[2]=e.m31;d[3]=e.m41;d[4]=e.m12;d[5]=e.m22;d[6]=e.m32;d[7]=e.m42;d[8]=e.m13;d[9]=e.m23;d[10]=e.m33;d[11]=e.m43;d[12]=e.m14;d[13]=e.m24;d[14]=e.m34;d[15]=e.m44;return Ia}function y(e){for(var d=Array(16),h=0;16>h;h++)d[h]=a.HEAPF32[e/4+h];return d}function K(e,d){return v(e,"HEAPF32",d||db)}function Q(e,d,h,l){var r=
Ub.toTypedArray();r[0]=e;r[1]=d;r[2]=h;r[3]=l;return db}function Z(e){for(var d=new Float32Array(4),h=0;4>h;h++)d[h]=a.HEAPF32[e/4+h];return d}function X(e,d){return v(e,"HEAPF32",d||ha)}function ma(e,d){return v(e,"HEAPF32",d||Vb)}function eb(){for(var e=0,d=0;d<arguments.length-1;d+=2)e+=arguments[d]*arguments[d+1];return e}function fb(e,d,h){for(var l=Array(e.length),r=0;r<h;r++)for(var z=0;z<h;z++){for(var C=0,H=0;H<h;H++)C+=e[h*r+H]*d[h*H+z];l[r*h+z]=C}return l}function Ac(e,d){for(var h=fb(d[0],
d[1],e),l=2;l<d.length;)h=fb(h,d[l],e),l++;return h}a.Color=function(e,d,h,l){void 0===l&&(l=1);return a.Color4f(p(e)/255,p(d)/255,p(h)/255,l)};a.ColorAsInt=function(e,d,h,l){void 0===l&&(l=255);return(p(l)<<24|p(e)<<16|p(d)<<8|p(h)<<0&268435455)>>>0};a.Color4f=function(e,d,h,l){void 0===l&&(l=1);return Float32Array.of(e,d,h,l)};Object.defineProperty(a,"TRANSPARENT",{get:function(){return a.Color4f(0,0,0,0)}});Object.defineProperty(a,"BLACK",{get:function(){return a.Color4f(0,0,0,1)}});Object.defineProperty(a,
"WHITE",{get:function(){return a.Color4f(1,1,1,1)}});Object.defineProperty(a,"RED",{get:function(){return a.Color4f(1,0,0,1)}});Object.defineProperty(a,"GREEN",{get:function(){return a.Color4f(0,1,0,1)}});Object.defineProperty(a,"BLUE",{get:function(){return a.Color4f(0,0,1,1)}});Object.defineProperty(a,"YELLOW",{get:function(){return a.Color4f(1,1,0,1)}});Object.defineProperty(a,"CYAN",{get:function(){return a.Color4f(0,1,1,1)}});Object.defineProperty(a,"MAGENTA",{get:function(){return a.Color4f(1,
0,1,1)}});a.getColorComponents=function(e){return[Math.floor(255*e[0]),Math.floor(255*e[1]),Math.floor(255*e[2]),e[3]]};a.parseColorString=function(e,d){e=e.toLowerCase();if(e.startsWith("#")){d=255;switch(e.length){case 9:d=parseInt(e.slice(7,9),16);case 7:var h=parseInt(e.slice(1,3),16);var l=parseInt(e.slice(3,5),16);var r=parseInt(e.slice(5,7),16);break;case 5:d=17*parseInt(e.slice(4,5),16);case 4:h=17*parseInt(e.slice(1,2),16),l=17*parseInt(e.slice(2,3),16),r=17*parseInt(e.slice(3,4),16)}return a.Color(h,
l,r,d/255)}return e.startsWith("rgba")?(e=e.slice(5,-1),e=e.split(","),a.Color(+e[0],+e[1],+e[2],q(e[3]))):e.startsWith("rgb")?(e=e.slice(4,-1),e=e.split(","),a.Color(+e[0],+e[1],+e[2],q(e[3]))):e.startsWith("gray(")||e.startsWith("hsl")||!d||(e=d[e],void 0===e)?a.BLACK:e};a.multiplyByAlpha=function(e,d){e=e.slice();e[3]=Math.max(0,Math.min(e[3]*d,1));return e};a.Malloc=function(e,d){var h=a._malloc(d*e.BYTES_PER_ELEMENT);return{_ck:!0,length:d,byteOffset:h,he:null,subarray:function(l,r){l=this.toTypedArray().subarray(l,
r);l._ck=!0;return l},toTypedArray:function(){if(this.he&&this.he.length)return this.he;this.he=new e(a.HEAPU8.buffer,h,d);this.he._ck=!0;return this.he}}};a.Free=function(e){a._free(e.byteOffset);e.byteOffset=da;e.toTypedArray=null;e.he=null};var Ha=da,xb,Ia=da,Tb,db=da,Ub,xa,ha=da,Bc,Qa=da,Cc,Wb=da,Dc,Xb=da,Ec,Yb=da,Fc,Vb=da,Gc,Hc=da,vd=Float32Array.of(0,0,1),Zb={};a.ie=function(){this.ce=[];this.Jd=null;Object.defineProperty(this,"length",{enumerable:!0,get:function(){return this.ce.length/4}})};
a.ie.prototype.push=function(e,d,h,l){this.Jd||this.ce.push(e,d,h,l)};a.ie.prototype.set=function(e,d,h,l,r){0>e||e>=this.ce.length/4||(e*=4,this.Jd?(e=this.Jd/4+e,a.HEAPF32[e]=d,a.HEAPF32[e+1]=h,a.HEAPF32[e+2]=l,a.HEAPF32[e+3]=r):(this.ce[e]=d,this.ce[e+1]=h,this.ce[e+2]=l,this.ce[e+3]=r))};a.ie.prototype.build=function(){return this.Jd?this.Jd:this.Jd=v(this.ce,"HEAPF32")};a.ie.prototype.delete=function(){this.Jd&&(a._free(this.Jd),this.Jd=null)};a.Ae=function(){this.Fe=[];this.Jd=null;Object.defineProperty(this,
"length",{enumerable:!0,get:function(){return this.Fe.length}})};a.Ae.prototype.push=function(e){this.Jd||this.Fe.push(e)};a.Ae.prototype.set=function(e,d){0>e||e>=this.Fe.length||(e*=4,this.Jd?a.HEAPU32[this.Jd/4+e]=d:this.Fe[e]=d)};a.Ae.prototype.build=function(){return this.Jd?this.Jd:this.Jd=v(this.Fe,"HEAPU32")};a.Ae.prototype.delete=function(){this.Jd&&(a._free(this.Jd),this.Jd=null)};a.RectBuilder=a.ie;a.RSXFormBuilder=a.ie;a.ColorBuilder=a.Ae;var da=0,wd=!(new Function("try {return this===window;}catch(e){ return false;}"))();
a.onRuntimeInitialized=function(){function e(d,h,l,r,z,C){C||(C=4*r.width,r.colorType===a.ColorType.RGBA_F16?C*=2:r.colorType===a.ColorType.RGBA_F32&&(C*=4));var H=C*r.height;var L=z?z.byteOffset:a._malloc(H);if(!d._readPixels(r,L,C,h,l))return z||a._free(L),null;if(z)return z.toTypedArray();switch(r.colorType){case a.ColorType.RGBA_8888:case a.ColorType.RGBA_F16:d=(new Uint8Array(a.HEAPU8.buffer,L,H)).slice();break;case a.ColorType.RGBA_F32:d=(new Float32Array(a.HEAPU8.buffer,L,H)).slice();break;
default:return null}a._free(L);return d}Ub=a.Malloc(Float32Array,4);db=Ub.byteOffset;Tb=a.Malloc(Float32Array,16);Ia=Tb.byteOffset;xb=a.Malloc(Float32Array,9);Ha=xb.byteOffset;Fc=a.Malloc(Float32Array,12);Vb=Fc.byteOffset;Gc=a.Malloc(Float32Array,12);Hc=Gc.byteOffset;xa=a.Malloc(Float32Array,4);ha=xa.byteOffset;Bc=a.Malloc(Float32Array,4);Qa=Bc.byteOffset;Cc=a.Malloc(Float32Array,3);Wb=Cc.byteOffset;Dc=a.Malloc(Float32Array,3);Xb=Dc.byteOffset;Ec=a.Malloc(Int32Array,4);Yb=Ec.byteOffset;a.ColorSpace.SRGB=
a.ColorSpace._MakeSRGB();a.ColorSpace.DISPLAY_P3=a.ColorSpace._MakeDisplayP3();a.ColorSpace.ADOBE_RGB=a.ColorSpace._MakeAdobeRGB();a.Path.MakeFromCmds=function(d){for(var h=0,l=0;l<d.length;l++)h+=d[l].length;if(Zb[h])var r=Zb[h];else r=new Float32Array(h),Zb[h]=r;var z=0;for(l=0;l<d.length;l++)for(var C=0;C<d[l].length;C++)r[z]=d[l][C],z++;d=[v(r,"HEAPF32"),h];h=a.Path._MakeFromCmds(d[0],d[1]);a._free(d[0]);return h};a.Path.MakeFromVerbsPointsWeights=function(d,h,l){var r=v(d,"HEAPU8"),z=v(h,"HEAPF32"),
C=v(l,"HEAPF32"),H=a.Path._MakeFromVerbsPointsWeights(r,d.length,z,h.length,C,l&&l.length||0);x(r,d);x(z,h);x(C,l);return H};a.Path.prototype.addArc=function(d,h,l){d=X(d);this._addArc(d,h,l);return this};a.Path.prototype.addOval=function(d,h,l){void 0===l&&(l=1);d=X(d);this._addOval(d,!!h,l);return this};a.Path.prototype.addPath=function(){var d=Array.prototype.slice.call(arguments),h=d[0],l=!1;"boolean"===typeof d[d.length-1]&&(l=d.pop());if(1===d.length)this._addPath(h,1,0,0,0,1,0,0,0,1,l);else if(2===
d.length)d=d[1],this._addPath(h,d[0],d[1],d[2],d[3],d[4],d[5],d[6]||0,d[7]||0,d[8]||1,l);else if(7===d.length||10===d.length)this._addPath(h,d[1],d[2],d[3],d[4],d[5],d[6],d[7]||0,d[8]||0,d[9]||1,l);else return null;return this};a.Path.prototype.addPoly=function(d,h){var l=v(d,"HEAPF32");this._addPoly(l,d.length/2,h);x(l,d);return this};a.Path.prototype.addRect=function(d,h){d=X(d);this._addRect(d,!!h);return this};a.Path.prototype.addRRect=function(d,h){d=ma(d);this._addRRect(d,!!h);return this};
a.Path.prototype.addVerbsPointsWeights=function(d,h,l){var r=v(d,"HEAPU8"),z=v(h,"HEAPF32"),C=v(l,"HEAPF32");this._addVerbsPointsWeights(r,d.length,z,h.length,C,l&&l.length||0);x(r,d);x(z,h);x(C,l)};a.Path.prototype.arc=function(d,h,l,r,z,C){d=a.LTRBRect(d-l,h-l,d+l,h+l);z=(z-r)/Math.PI*180-360*!!C;C=new a.Path;C.addArc(d,r/Math.PI*180,z);this.addPath(C,!0);C.delete();return this};a.Path.prototype.arcToOval=function(d,h,l,r){d=X(d);this._arcToOval(d,h,l,r);return this};a.Path.prototype.arcToRotated=
function(d,h,l,r,z,C,H){this._arcToRotated(d,h,l,!!r,!!z,C,H);return this};a.Path.prototype.arcToTangent=function(d,h,l,r,z){this._arcToTangent(d,h,l,r,z);return this};a.Path.prototype.close=function(){this._close();return this};a.Path.prototype.conicTo=function(d,h,l,r,z){this._conicTo(d,h,l,r,z);return this};a.Path.prototype.computeTightBounds=function(d){this._computeTightBounds(ha);var h=xa.toTypedArray();return d?(d.set(h),d):h.slice()};a.Path.prototype.cubicTo=function(d,h,l,r,z,C){this._cubicTo(d,
h,l,r,z,C);return this};a.Path.prototype.dash=function(d,h,l){return this._dash(d,h,l)?this:null};a.Path.prototype.getBounds=function(d){this._getBounds(ha);var h=xa.toTypedArray();return d?(d.set(h),d):h.slice()};a.Path.prototype.lineTo=function(d,h){this._lineTo(d,h);return this};a.Path.prototype.moveTo=function(d,h){this._moveTo(d,h);return this};a.Path.prototype.offset=function(d,h){this._transform(1,0,d,0,1,h,0,0,1);return this};a.Path.prototype.quadTo=function(d,h,l,r){this._quadTo(d,h,l,r);
return this};a.Path.prototype.rArcTo=function(d,h,l,r,z,C,H){this._rArcTo(d,h,l,r,z,C,H);return this};a.Path.prototype.rConicTo=function(d,h,l,r,z){this._rConicTo(d,h,l,r,z);return this};a.Path.prototype.rCubicTo=function(d,h,l,r,z,C){this._rCubicTo(d,h,l,r,z,C);return this};a.Path.prototype.rLineTo=function(d,h){this._rLineTo(d,h);return this};a.Path.prototype.rMoveTo=function(d,h){this._rMoveTo(d,h);return this};a.Path.prototype.rQuadTo=function(d,h,l,r){this._rQuadTo(d,h,l,r);return this};a.Path.prototype.stroke=
function(d){d=d||{};d.width=d.width||1;d.miter_limit=d.miter_limit||4;d.cap=d.cap||a.StrokeCap.Butt;d.join=d.join||a.StrokeJoin.Miter;d.precision=d.precision||1;return this._stroke(d)?this:null};a.Path.prototype.transform=function(){if(1===arguments.length){var d=arguments[0];this._transform(d[0],d[1],d[2],d[3],d[4],d[5],d[6]||0,d[7]||0,d[8]||1)}else if(6===arguments.length||9===arguments.length)d=arguments,this._transform(d[0],d[1],d[2],d[3],d[4],d[5],d[6]||0,d[7]||0,d[8]||1);else throw"transform expected to take 1 or 9 arguments. Got "+
arguments.length;return this};a.Path.prototype.trim=function(d,h,l){return this._trim(d,h,!!l)?this:null};a.Image.prototype.makeShaderCubic=function(d,h,l,r,z){z=J(z);return this._makeShaderCubic(d,h,l,r,z)};a.Image.prototype.makeShaderOptions=function(d,h,l,r,z){z=J(z);return this._makeShaderOptions(d,h,l,r,z)};a.Image.prototype.readPixels=function(d,h,l,r,z){return e(this,d,h,l,r,z)};a.Canvas.prototype.clear=function(d){d=K(d);this._clear(d)};a.Canvas.prototype.clipRRect=function(d,h,l){d=ma(d);
this._clipRRect(d,h,l)};a.Canvas.prototype.clipRect=function(d,h,l){d=X(d);this._clipRect(d,h,l)};a.Canvas.prototype.concat=function(d){d=O(d);this._concat(d)};a.Canvas.prototype.drawArc=function(d,h,l,r,z){d=X(d);this._drawArc(d,h,l,r,z)};a.Canvas.prototype.drawAtlas=function(d,h,l,r,z,C,H){if(d&&r&&h&&l&&h.length===l.length){z||(z=a.BlendMode.SrcOver);var L=h.build?h.build():v(h,"HEAPF32");if(l.build){var N=l.build();var R=l.length}else N=v(l,"HEAPF32"),R=l.length/4;var P=da;C&&(P=C.build?C.build():
v(m(C),"HEAPU32"));if(H&&"B"in H&&"C"in H)this._drawAtlasCubic(d,N,L,P,R,z,H.B,H.C,r);else{let ea=a.FilterMode.Linear,oa=a.MipmapMode.None;H&&(ea=H.filter,"mipmap"in H&&(oa=H.mipmap));this._drawAtlasOptions(d,N,L,P,R,z,ea,oa,r)}L&&!h.build&&x(L,h);N&&!l.build&&x(N,l);P&&!C.build&&x(P,C)}};a.Canvas.prototype.drawColor=function(d,h){d=K(d);void 0!==h?this._drawColor(d,h):this._drawColor(d)};a.Canvas.prototype.drawColorComponents=function(d,h,l,r,z){d=Q(d,h,l,r);void 0!==z?this._drawColor(d,z):this._drawColor(d)};
a.Canvas.prototype.drawDRRect=function(d,h,l){d=ma(d,Vb);h=ma(h,Hc);this._drawDRRect(d,h,l)};a.Canvas.prototype.drawImageNine=function(d,h,l,r,z){h=v(h,"HEAP32",Yb);l=X(l);this._drawImageNine(d,h,l,r,z||null)};a.Canvas.prototype.drawImageRect=function(d,h,l,r,z){X(h,ha);X(l,Qa);this._drawImageRect(d,ha,Qa,r,!!z)};a.Canvas.prototype.drawImageRectCubic=function(d,h,l,r,z,C){X(h,ha);X(l,Qa);this._drawImageRectCubic(d,ha,Qa,r,z,C||null)};a.Canvas.prototype.drawImageRectOptions=function(d,h,l,r,z,C){X(h,
ha);X(l,Qa);this._drawImageRectOptions(d,ha,Qa,r,z,C||null)};a.Canvas.prototype.drawOval=function(d,h){d=X(d);this._drawOval(d,h)};a.Canvas.prototype.drawPoints=function(d,h,l){var r=v(h,"HEAPF32");this._drawPoints(d,r,h.length/2,l);x(r,h)};a.Canvas.prototype.drawRRect=function(d,h){d=ma(d);this._drawRRect(d,h)};a.Canvas.prototype.drawRect=function(d,h){d=X(d);this._drawRect(d,h)};a.Canvas.prototype.drawShadow=function(d,h,l,r,z,C,H){var L=v(z,"HEAPF32"),N=v(C,"HEAPF32");h=v(h,"HEAPF32",Wb);l=v(l,
"HEAPF32",Xb);this._drawShadow(d,h,l,r,L,N,H);x(L,z);x(N,C)};a.getShadowLocalBounds=function(d,h,l,r,z,C,H){d=J(d);l=v(l,"HEAPF32",Wb);r=v(r,"HEAPF32",Xb);if(!this._getShadowLocalBounds(d,h,l,r,z,C,ha))return null;h=xa.toTypedArray();return H?(H.set(h),H):h.slice()};a.Canvas.prototype.getLocalToDevice=function(){this._getLocalToDevice(Ia);return y(Ia)};a.Canvas.prototype.findMarkedCTM=function(d){return this._findMarkedCTM(d,Ia)?y(Ia):null};a.Canvas.prototype.getTotalMatrix=function(){this._getTotalMatrix(Ha);
for(var d=Array(9),h=0;9>h;h++)d[h]=a.HEAPF32[Ha/4+h];return d};a.Canvas.prototype.readPixels=function(d,h,l,r,z){return e(this,d,h,l,r,z)};a.Canvas.prototype.saveLayer=function(d,h,l,r){h=X(h);return this._saveLayer(d||null,h,l||null,r||0)};a.Canvas.prototype.writePixels=function(d,h,l,r,z,C,H,L){if(d.byteLength%(h*l))throw"pixels length must be a multiple of the srcWidth * srcHeight";var N=d.byteLength/(h*l);C=C||a.AlphaType.Unpremul;H=H||a.ColorType.RGBA_8888;L=L||a.ColorSpace.SRGB;var R=N*h;N=
v(d,"HEAPU8");h=this._writePixels({width:h,height:l,colorType:H,alphaType:C,colorSpace:L},N,R,r,z);x(N,d);return h};a.ColorFilter.MakeBlend=function(d,h){d=K(d);return a.ColorFilter._MakeBlend(d,h)};a.ColorFilter.MakeMatrix=function(d){if(!d||20!==d.length)throw"invalid color matrix";var h=v(d,"HEAPF32"),l=a.ColorFilter._makeMatrix(h);x(h,d);return l};a.ContourMeasure.prototype.getPosTan=function(d,h){this._getPosTan(d,ha);d=xa.toTypedArray();return h?(h.set(d),h):d.slice()};a.ImageFilter.MakeMatrixTransform=
function(d,h,l){d=J(d);return a.ImageFilter._MakeMatrixTransform(d,h,l)};a.Paint.prototype.getColor=function(){this._getColor(db);return Z(db)};a.Paint.prototype.setColor=function(d,h){h=h||null;d=K(d);this._setColor(d,h)};a.Paint.prototype.setColorComponents=function(d,h,l,r,z){z=z||null;d=Q(d,h,l,r);this._setColor(d,z)};a.Path.prototype.getPoint=function(d,h){this._getPoint(d,ha);d=xa.toTypedArray();return h?(h[0]=d[0],h[1]=d[1],h):d.slice(0,2)};a.PictureRecorder.prototype.beginRecording=function(d){d=
X(d);return this._beginRecording(d)};a.Surface.prototype.makeImageSnapshot=function(d){d=v(d,"HEAP32",Yb);return this._makeImageSnapshot(d)};a.Surface.prototype.requestAnimationFrame=function(d,h){this.Be||(this.Be=this.getCanvas());requestAnimationFrame(function(){void 0!==this.de&&a.setCurrentContext(this.de);d(this.Be);this.flush(h)}.bind(this))};a.Surface.prototype.drawOnce=function(d,h){this.Be||(this.Be=this.getCanvas());requestAnimationFrame(function(){void 0!==this.de&&a.setCurrentContext(this.de);
d(this.Be);this.flush(h);this.dispose()}.bind(this))};a.PathEffect.MakeDash=function(d,h){h||(h=0);if(!d.length||1===d.length%2)throw"Intervals array must have even length";var l=v(d,"HEAPF32");h=a.PathEffect._MakeDash(l,d.length,h);x(l,d);return h};a.Shader.MakeColor=function(d,h){h=h||null;d=K(d);return a.Shader._MakeColor(d,h)};a.Shader.Blend=a.Shader.MakeBlend;a.Shader.Color=a.Shader.MakeColor;a.Shader.Lerp=a.Shader.MakeLerp;a.Shader.MakeLinearGradient=function(d,h,l,r,z,C,H,L){L=L||null;var N=
G(l),R=v(r,"HEAPF32");H=H||0;C=J(C);var P=xa.toTypedArray();P.set(d);P.set(h,2);d=a.Shader._MakeLinearGradient(ha,N.Ud,N.colorType,R,N.count,z,H,C,L);x(N.Ud,l);r&&x(R,r);return d};a.Shader.MakeRadialGradient=function(d,h,l,r,z,C,H,L){L=L||null;var N=G(l),R=v(r,"HEAPF32");H=H||0;C=J(C);d=a.Shader._MakeRadialGradient(d[0],d[1],h,N.Ud,N.colorType,R,N.count,z,H,C,L);x(N.Ud,l);r&&x(R,r);return d};a.Shader.MakeSweepGradient=function(d,h,l,r,z,C,H,L,N,R){R=R||null;var P=G(l),ea=v(r,"HEAPF32");H=H||0;L=L||
0;N=N||360;C=J(C);d=a.Shader._MakeSweepGradient(d,h,P.Ud,P.colorType,ea,P.count,z,L,N,H,C,R);x(P.Ud,l);r&&x(ea,r);return d};a.Shader.MakeTwoPointConicalGradient=function(d,h,l,r,z,C,H,L,N,R){R=R||null;var P=G(z),ea=v(C,"HEAPF32");N=N||0;L=J(L);var oa=xa.toTypedArray();oa.set(d);oa.set(l,2);d=a.Shader._MakeTwoPointConicalGradient(ha,h,r,P.Ud,P.colorType,ea,P.count,H,N,L,R);x(P.Ud,z);C&&x(ea,C);return d};a.Vertices.prototype.bounds=function(d){this._bounds(ha);var h=xa.toTypedArray();return d?(d.set(h),
d):h.slice()};a.Qd&&a.Qd.forEach(function(d){d()})};a.computeTonalColors=function(e){var d=v(e.ambient,"HEAPF32"),h=v(e.spot,"HEAPF32");this._computeTonalColors(d,h);var l={ambient:Z(d),spot:Z(h)};x(d,e.ambient);x(h,e.spot);return l};a.LTRBRect=function(e,d,h,l){return Float32Array.of(e,d,h,l)};a.XYWHRect=function(e,d,h,l){return Float32Array.of(e,d,e+h,d+l)};a.LTRBiRect=function(e,d,h,l){return Int32Array.of(e,d,h,l)};a.XYWHiRect=function(e,d,h,l){return Int32Array.of(e,d,e+h,d+l)};a.RRectXY=function(e,
d,h){return Float32Array.of(e[0],e[1],e[2],e[3],d,h,d,h,d,h,d,h)};a.MakeAnimatedImageFromEncoded=function(e){e=new Uint8Array(e);var d=a._malloc(e.byteLength);a.HEAPU8.set(e,d);return(e=a._decodeAnimatedImage(d,e.byteLength))?e:null};a.MakeImageFromEncoded=function(e){e=new Uint8Array(e);var d=a._malloc(e.byteLength);a.HEAPU8.set(e,d);return(e=a._decodeImage(d,e.byteLength))?e:null};var gb=null;a.MakeImageFromCanvasImageSource=function(e){var d=e.width,h=e.height;gb||(gb=document.createElement("canvas"));
gb.width=d;gb.height=h;var l=gb.getContext("2d");l.drawImage(e,0,0);e=l.getImageData(0,0,d,h);return a.MakeImage({width:d,height:h,alphaType:a.AlphaType.Unpremul,colorType:a.ColorType.RGBA_8888,colorSpace:a.ColorSpace.SRGB},e.data,4*d)};a.MakeImage=function(e,d,h){var l=a._malloc(d.length);a.HEAPU8.set(d,l);return a._MakeImage(e,l,d.length,h)};a.MakeVertices=function(e,d,h,l,r,z){var C=r&&r.length||0,H=0;h&&h.length&&(H|=1);l&&l.length&&(H|=2);void 0===z||z||(H|=4);e=new a._VerticesBuilder(e,d.length/
2,C,H);v(d,"HEAPF32",e.positions());e.texCoords()&&v(h,"HEAPF32",e.texCoords());if(e.colors()){if(l.build)throw"Color builder not accepted by MakeVertices, use array of ints";v(m(l),"HEAPU32",e.colors())}e.indices()&&v(r,"HEAPU16",e.indices());return e.detach()};a.Matrix={};a.Matrix.identity=function(){return c(3)};a.Matrix.invert=function(e){var d=e[0]*e[4]*e[8]+e[1]*e[5]*e[6]+e[2]*e[3]*e[7]-e[2]*e[4]*e[6]-e[1]*e[3]*e[8]-e[0]*e[5]*e[7];return d?[(e[4]*e[8]-e[5]*e[7])/d,(e[2]*e[7]-e[1]*e[8])/d,(e[1]*
e[5]-e[2]*e[4])/d,(e[5]*e[6]-e[3]*e[8])/d,(e[0]*e[8]-e[2]*e[6])/d,(e[2]*e[3]-e[0]*e[5])/d,(e[3]*e[7]-e[4]*e[6])/d,(e[1]*e[6]-e[0]*e[7])/d,(e[0]*e[4]-e[1]*e[3])/d]:null};a.Matrix.mapPoints=function(e,d){for(var h=0;h<d.length;h+=2){var l=d[h],r=d[h+1],z=e[6]*l+e[7]*r+e[8],C=e[3]*l+e[4]*r+e[5];d[h]=(e[0]*l+e[1]*r+e[2])/z;d[h+1]=C/z}return d};a.Matrix.multiply=function(){return Ac(3,arguments)};a.Matrix.rotated=function(e,d,h){d=d||0;h=h||0;var l=Math.sin(e);e=Math.cos(e);return[e,-l,eb(l,h,1-e,d),l,
e,eb(-l,d,1-e,h),0,0,1]};a.Matrix.scaled=function(e,d,h,l){h=h||0;l=l||0;var r=b([e,d],c(3),3,0,1);return b([h-e*h,l-d*l],r,3,2,0)};a.Matrix.skewed=function(e,d,h,l){h=h||0;l=l||0;var r=b([e,d],c(3),3,1,-1);return b([-e*h,-d*l],r,3,2,0)};a.Matrix.translated=function(e,d){return b(arguments,c(3),3,2,0)};a.Vector={};a.Vector.dot=function(e,d){return e.map(function(h,l){return h*d[l]}).reduce(function(h,l){return h+l})};a.Vector.lengthSquared=function(e){return a.Vector.dot(e,e)};a.Vector.length=function(e){return Math.sqrt(a.Vector.lengthSquared(e))};
a.Vector.mulScalar=function(e,d){return e.map(function(h){return h*d})};a.Vector.add=function(e,d){return e.map(function(h,l){return h+d[l]})};a.Vector.sub=function(e,d){return e.map(function(h,l){return h-d[l]})};a.Vector.dist=function(e,d){return a.Vector.length(a.Vector.sub(e,d))};a.Vector.normalize=function(e){return a.Vector.mulScalar(e,1/a.Vector.length(e))};a.Vector.cross=function(e,d){return[e[1]*d[2]-e[2]*d[1],e[2]*d[0]-e[0]*d[2],e[0]*d[1]-e[1]*d[0]]};a.M44={};a.M44.identity=function(){return c(4)};
a.M44.translated=function(e){return b(e,c(4),4,3,0)};a.M44.scaled=function(e){return b(e,c(4),4,0,1)};a.M44.rotated=function(e,d){return a.M44.rotatedUnitSinCos(a.Vector.normalize(e),Math.sin(d),Math.cos(d))};a.M44.rotatedUnitSinCos=function(e,d,h){var l=e[0],r=e[1];e=e[2];var z=1-h;return[z*l*l+h,z*l*r-d*e,z*l*e+d*r,0,z*l*r+d*e,z*r*r+h,z*r*e-d*l,0,z*l*e-d*r,z*r*e+d*l,z*e*e+h,0,0,0,0,1]};a.M44.lookat=function(e,d,h){d=a.Vector.normalize(a.Vector.sub(d,e));h=a.Vector.normalize(h);h=a.Vector.normalize(a.Vector.cross(d,
h));var l=a.M44.identity();b(h,l,4,0,0);b(a.Vector.cross(h,d),l,4,1,0);b(a.Vector.mulScalar(d,-1),l,4,2,0);b(e,l,4,3,0);e=a.M44.invert(l);return null===e?a.M44.identity():e};a.M44.perspective=function(e,d,h){var l=1/(d-e);h/=2;h=Math.cos(h)/Math.sin(h);return[h,0,0,0,0,h,0,0,0,0,(d+e)*l,2*d*e*l,0,0,-1,1]};a.M44.rc=function(e,d,h){return e[4*d+h]};a.M44.multiply=function(){return Ac(4,arguments)};a.M44.invert=function(e){var d=e[0],h=e[4],l=e[8],r=e[12],z=e[1],C=e[5],H=e[9],L=e[13],N=e[2],R=e[6],P=
e[10],ea=e[14],oa=e[3],Ca=e[7],Da=e[11];e=e[15];var Ja=d*C-h*z,Ka=d*H-l*z,pa=d*L-r*z,E=h*H-l*C,k=h*L-r*C,n=l*L-r*H,w=N*Ca-R*oa,A=N*Da-P*oa,B=N*e-ea*oa,D=R*Da-P*Ca,I=R*e-ea*Ca,U=P*e-ea*Da,na=Ja*U-Ka*I+pa*D+E*B-k*A+n*w,ca=1/na;if(0===na||Infinity===ca)return null;Ja*=ca;Ka*=ca;pa*=ca;E*=ca;k*=ca;n*=ca;w*=ca;A*=ca;B*=ca;D*=ca;I*=ca;U*=ca;d=[C*U-H*I+L*D,H*B-z*U-L*A,z*I-C*B+L*w,C*A-z*D-H*w,l*I-h*U-r*D,d*U-l*B+r*A,h*B-d*I-r*w,d*D-h*A+l*w,Ca*n-Da*k+e*E,Da*pa-oa*n-e*Ka,oa*k-Ca*pa+e*Ja,Ca*Ka-oa*E-Da*Ja,P*
k-R*n-ea*E,N*n-P*pa+ea*Ka,R*pa-N*k-ea*Ja,N*E-R*Ka+P*Ja];return d.every(function(ya){return!isNaN(ya)&&Infinity!==ya&&-Infinity!==ya})?d:null};a.M44.transpose=function(e){return[e[0],e[4],e[8],e[12],e[1],e[5],e[9],e[13],e[2],e[6],e[10],e[14],e[3],e[7],e[11],e[15]]};a.M44.mustInvert=function(e){e=a.M44.invert(e);if(null===e)throw"Matrix not invertible";return e};a.M44.setupCamera=function(e,d,h){var l=a.M44.lookat(h.eye,h.coa,h.up);h=a.M44.perspective(h.near,h.far,h.angle);d=[(e[2]-e[0])/2,(e[3]-e[1])/
2,d];e=a.M44.multiply(a.M44.translated([(e[0]+e[2])/2,(e[1]+e[3])/2,0]),a.M44.scaled(d));return a.M44.multiply(e,h,l,a.M44.mustInvert(e))};a.ColorMatrix={};a.ColorMatrix.identity=function(){var e=new Float32Array(20);e[0]=1;e[6]=1;e[12]=1;e[18]=1;return e};a.ColorMatrix.scaled=function(e,d,h,l){var r=new Float32Array(20);r[0]=e;r[6]=d;r[12]=h;r[18]=l;return r};var xd=[[6,7,11,12],[0,10,2,12],[0,1,5,6]];a.ColorMatrix.rotated=function(e,d,h){var l=a.ColorMatrix.identity();e=xd[e];l[e[0]]=h;l[e[1]]=
d;l[e[2]]=-d;l[e[3]]=h;return l};a.ColorMatrix.postTranslate=function(e,d,h,l,r){e[4]+=d;e[9]+=h;e[14]+=l;e[19]+=r;return e};a.ColorMatrix.concat=function(e,d){for(var h=new Float32Array(20),l=0,r=0;20>r;r+=5){for(var z=0;4>z;z++)h[l++]=e[r]*d[z]+e[r+1]*d[z+5]+e[r+2]*d[z+10]+e[r+3]*d[z+15];h[l++]=e[r]*d[4]+e[r+1]*d[9]+e[r+2]*d[14]+e[r+3]*d[19]+e[r+4]}return h};a.Qd=a.Qd||[];a.Qd.push(function(){a.Path.prototype.op=function(e,d){return this._op(e,d)?this:null};a.Path.prototype.simplify=function(){return this._simplify()?
this:null}});a.Qd=a.Qd||[];a.Qd.push(function(){a.Canvas.prototype.drawText=function(e,d,h,l,r){var z=ka(e),C=a._malloc(z+1);la(e,F,C,z+1);this._drawSimpleText(C,z,d,h,r,l);a._free(C)};a.Font.prototype.getGlyphBounds=function(e,d,h){var l=v(e,"HEAPU16"),r=a._malloc(16*e.length);this._getGlyphWidthBounds(l,e.length,da,r,d||null);d=new Float32Array(a.HEAPU8.buffer,r,4*e.length);x(l,e);if(h)return h.set(d),a._free(r),h;e=Float32Array.from(d);a._free(r);return e};a.Font.prototype.getGlyphIDs=function(e,
d,h){d||(d=e.length);var l=ka(e)+1,r=a._malloc(l);la(e,F,r,l);e=a._malloc(2*d);d=this._getGlyphIDs(r,l-1,d,e);a._free(r);if(0>d)return a._free(e),null;r=new Uint16Array(a.HEAPU8.buffer,e,d);if(h)return h.set(r),a._free(e),h;h=Uint32Array.from(r);a._free(e);return h};a.Font.prototype.getGlyphWidths=function(e,d,h){var l=v(e,"HEAPU16"),r=a._malloc(4*e.length);this._getGlyphWidthBounds(l,e.length,r,da,d||null);d=new Float32Array(a.HEAPU8.buffer,r,e.length);x(l,e);if(h)return h.set(d),a._free(r),h;e=
Float32Array.from(d);a._free(r);return e};a.FontMgr.FromData=function(){if(!arguments.length)return null;var e=arguments;1===e.length&&Array.isArray(e[0])&&(e=arguments[0]);if(!e.length)return null;for(var d=[],h=[],l=0;l<e.length;l++){var r=new Uint8Array(e[l]),z=v(r,"HEAPU8");d.push(z);h.push(r.byteLength)}d=v(d,"HEAPU32");h=v(h,"HEAPU32");e=a.FontMgr._fromData(d,h,e.length);a._free(d);a._free(h);return e};a.FontMgr.prototype.MakeTypefaceFromData=function(e){e=new Uint8Array(e);var d=v(e,"HEAPU8");
return(e=this._makeTypefaceFromData(d,e.byteLength))?e:null};a.TextBlob.MakeOnPath=function(e,d,h,l){if(e&&e.length&&d&&d.countPoints()){if(1===d.countPoints())return this.MakeFromText(e,h);l||(l=0);var r=h.getGlyphIDs(e),z=h.getGlyphWidths(r);r=new a.RSXFormBuilder;d=new a.ContourMeasureIter(d,!1,1);for(var C=d.next(),H=new Float32Array(4),L=0;L<e.length&&C;L++){var N=z[L];l+=N/2;if(l>C.length()){C.delete();C=d.next();if(!C){e=e.substring(0,L);break}l=N/2}C.getPosTan(l,H);var R=H[2],P=H[3];r.push(R,
P,H[0]-N/2*R,H[1]-N/2*P);l+=N/2}e=this.MakeFromRSXform(e,r,h);r.delete();C&&C.delete();d.delete();return e}};a.TextBlob.MakeFromRSXform=function(e,d,h){var l=ka(e)+1,r=a._malloc(l);la(e,F,r,l);e=d.build?d.build():v(d,"HEAPF32");h=a.TextBlob._MakeFromRSXform(r,l-1,e,h);a._free(r);return h?h:null};a.TextBlob.MakeFromRSXformGlyphs=function(e,d,h){var l=v(e,"HEAPU16");d=d.build?d.build():v(d,"HEAPF32");h=a.TextBlob._MakeFromRSXformGlyphs(l,2*e.length,d,h);x(l,e);return h?h:null};a.TextBlob.MakeFromGlyphs=
function(e,d){var h=v(e,"HEAPU16");d=a.TextBlob._MakeFromGlyphs(h,2*e.length,d);x(h,e);return d?d:null};a.TextBlob.MakeFromText=function(e,d){var h=ka(e)+1,l=a._malloc(h);la(e,F,l,h);e=a.TextBlob._MakeFromText(l,h-1,d);a._free(l);return e?e:null};a.MallocGlyphIDs=function(e){return a.Malloc(Uint16Array,e)}});(function(){function e(E){for(var k=0;k<E.length;k++)if(void 0!==E[k]&&!Number.isFinite(E[k]))return!1;return!0}function d(E){var k=a.getColorComponents(E);E=k[0];var n=k[1],w=k[2];k=k[3];if(1===
k)return E=E.toString(16).toLowerCase(),n=n.toString(16).toLowerCase(),w=w.toString(16).toLowerCase(),E=1===E.length?"0"+E:E,n=1===n.length?"0"+n:n,w=1===w.length?"0"+w:w,"#"+E+n+w;k=0===k||1===k?k:k.toFixed(8);return"rgba("+E+", "+n+", "+w+", "+k+")"}function h(E){return a.parseColorString(E,Ja)}function l(E){E=Ka.exec(E);if(!E)return null;var k=parseFloat(E[4]),n=16;switch(E[5]){case "em":case "rem":n=16*k;break;case "pt":n=4*k/3;break;case "px":n=k;break;case "pc":n=16*k;break;case "in":n=96*k;
break;case "cm":n=96*k/2.54;break;case "mm":n=96/25.4*k;break;case "q":n=96/25.4/4*k;break;case "%":n=16/75*k}return{style:E[1],variant:E[2],weight:E[3],sizePx:n,family:E[6].trim()}}function r(E){this.Dd=E;this.Fd=new a.Paint;this.Fd.setAntiAlias(!0);this.Fd.setStrokeMiter(10);this.Fd.setStrokeCap(a.StrokeCap.Butt);this.Fd.setStrokeJoin(a.StrokeJoin.Miter);this.Ke="10px monospace";this.le=new a.Font(null,10);this.le.setSubpixel(!0);this.Sd=this.Xd=a.BLACK;this.oe=0;this.De=a.TRANSPARENT;this.qe=this.pe=
0;this.Ee=this.$d=1;this.Ce=0;this.ne=[];this.Ed=a.BlendMode.SrcOver;this.Fd.setStrokeWidth(this.Ee);this.Fd.setBlendMode(this.Ed);this.Hd=new a.Path;this.Id=a.Matrix.identity();this.ef=[];this.ue=[];this.be=function(){this.Hd.delete();this.Fd.delete();this.le.delete();this.ue.forEach(function(k){k.be()})};Object.defineProperty(this,"currentTransform",{enumerable:!0,get:function(){return{a:this.Id[0],c:this.Id[1],e:this.Id[2],b:this.Id[3],d:this.Id[4],f:this.Id[5]}},set:function(k){k.a&&this.setTransform(k.a,
k.b,k.c,k.d,k.e,k.f)}});Object.defineProperty(this,"fillStyle",{enumerable:!0,get:function(){return f(this.Sd)?d(this.Sd):this.Sd},set:function(k){"string"===typeof k?this.Sd=h(k):k.me&&(this.Sd=k)}});Object.defineProperty(this,"font",{enumerable:!0,get:function(){return this.Ke},set:function(k){var n=l(k),w=n.family;n.typeface=pa[w]?pa[w][(n.style||"normal")+"|"+(n.variant||"normal")+"|"+(n.weight||"normal")]||pa[w]["*"]:null;n&&(this.le.setSize(n.sizePx),this.le.setTypeface(n.typeface),this.Ke=
k)}});Object.defineProperty(this,"globalAlpha",{enumerable:!0,get:function(){return this.$d},set:function(k){!isFinite(k)||0>k||1<k||(this.$d=k)}});Object.defineProperty(this,"globalCompositeOperation",{enumerable:!0,get:function(){switch(this.Ed){case a.BlendMode.SrcOver:return"source-over";case a.BlendMode.DstOver:return"destination-over";case a.BlendMode.Src:return"copy";case a.BlendMode.Dst:return"destination";case a.BlendMode.Clear:return"clear";case a.BlendMode.SrcIn:return"source-in";case a.BlendMode.DstIn:return"destination-in";
case a.BlendMode.SrcOut:return"source-out";case a.BlendMode.DstOut:return"destination-out";case a.BlendMode.SrcATop:return"source-atop";case a.BlendMode.DstATop:return"destination-atop";case a.BlendMode.Xor:return"xor";case a.BlendMode.Plus:return"lighter";case a.BlendMode.Multiply:return"multiply";case a.BlendMode.Screen:return"screen";case a.BlendMode.Overlay:return"overlay";case a.BlendMode.Darken:return"darken";case a.BlendMode.Lighten:return"lighten";case a.BlendMode.ColorDodge:return"color-dodge";
case a.BlendMode.ColorBurn:return"color-burn";case a.BlendMode.HardLight:return"hard-light";case a.BlendMode.SoftLight:return"soft-light";case a.BlendMode.Difference:return"difference";case a.BlendMode.Exclusion:return"exclusion";case a.BlendMode.Hue:return"hue";case a.BlendMode.Saturation:return"saturation";case a.BlendMode.Color:return"color";case a.BlendMode.Luminosity:return"luminosity"}},set:function(k){switch(k){case "source-over":this.Ed=a.BlendMode.SrcOver;break;case "destination-over":this.Ed=
a.BlendMode.DstOver;break;case "copy":this.Ed=a.BlendMode.Src;break;case "destination":this.Ed=a.BlendMode.Dst;break;case "clear":this.Ed=a.BlendMode.Clear;break;case "source-in":this.Ed=a.BlendMode.SrcIn;break;case "destination-in":this.Ed=a.BlendMode.DstIn;break;case "source-out":this.Ed=a.BlendMode.SrcOut;break;case "destination-out":this.Ed=a.BlendMode.DstOut;break;case "source-atop":this.Ed=a.BlendMode.SrcATop;break;case "destination-atop":this.Ed=a.BlendMode.DstATop;break;case "xor":this.Ed=
a.BlendMode.Xor;break;case "lighter":this.Ed=a.BlendMode.Plus;break;case "plus-lighter":this.Ed=a.BlendMode.Plus;break;case "plus-darker":throw"plus-darker is not supported";case "multiply":this.Ed=a.BlendMode.Multiply;break;case "screen":this.Ed=a.BlendMode.Screen;break;case "overlay":this.Ed=a.BlendMode.Overlay;break;case "darken":this.Ed=a.BlendMode.Darken;break;case "lighten":this.Ed=a.BlendMode.Lighten;break;case "color-dodge":this.Ed=a.BlendMode.ColorDodge;break;case "color-burn":this.Ed=a.BlendMode.ColorBurn;
break;case "hard-light":this.Ed=a.BlendMode.HardLight;break;case "soft-light":this.Ed=a.BlendMode.SoftLight;break;case "difference":this.Ed=a.BlendMode.Difference;break;case "exclusion":this.Ed=a.BlendMode.Exclusion;break;case "hue":this.Ed=a.BlendMode.Hue;break;case "saturation":this.Ed=a.BlendMode.Saturation;break;case "color":this.Ed=a.BlendMode.Color;break;case "luminosity":this.Ed=a.BlendMode.Luminosity;break;default:return}this.Fd.setBlendMode(this.Ed)}});Object.defineProperty(this,"imageSmoothingEnabled",
{enumerable:!0,get:function(){return!0},set:function(){}});Object.defineProperty(this,"imageSmoothingQuality",{enumerable:!0,get:function(){return"high"},set:function(){}});Object.defineProperty(this,"lineCap",{enumerable:!0,get:function(){switch(this.Fd.getStrokeCap()){case a.StrokeCap.Butt:return"butt";case a.StrokeCap.Round:return"round";case a.StrokeCap.Square:return"square"}},set:function(k){switch(k){case "butt":this.Fd.setStrokeCap(a.StrokeCap.Butt);break;case "round":this.Fd.setStrokeCap(a.StrokeCap.Round);
break;case "square":this.Fd.setStrokeCap(a.StrokeCap.Square)}}});Object.defineProperty(this,"lineDashOffset",{enumerable:!0,get:function(){return this.Ce},set:function(k){isFinite(k)&&(this.Ce=k)}});Object.defineProperty(this,"lineJoin",{enumerable:!0,get:function(){switch(this.Fd.getStrokeJoin()){case a.StrokeJoin.Miter:return"miter";case a.StrokeJoin.Round:return"round";case a.StrokeJoin.Bevel:return"bevel"}},set:function(k){switch(k){case "miter":this.Fd.setStrokeJoin(a.StrokeJoin.Miter);break;
case "round":this.Fd.setStrokeJoin(a.StrokeJoin.Round);break;case "bevel":this.Fd.setStrokeJoin(a.StrokeJoin.Bevel)}}});Object.defineProperty(this,"lineWidth",{enumerable:!0,get:function(){return this.Fd.getStrokeWidth()},set:function(k){0>=k||!k||(this.Ee=k,this.Fd.setStrokeWidth(k))}});Object.defineProperty(this,"miterLimit",{enumerable:!0,get:function(){return this.Fd.getStrokeMiter()},set:function(k){0>=k||!k||this.Fd.setStrokeMiter(k)}});Object.defineProperty(this,"shadowBlur",{enumerable:!0,
get:function(){return this.oe},set:function(k){0>k||!isFinite(k)||(this.oe=k)}});Object.defineProperty(this,"shadowColor",{enumerable:!0,get:function(){return d(this.De)},set:function(k){this.De=h(k)}});Object.defineProperty(this,"shadowOffsetX",{enumerable:!0,get:function(){return this.pe},set:function(k){isFinite(k)&&(this.pe=k)}});Object.defineProperty(this,"shadowOffsetY",{enumerable:!0,get:function(){return this.qe},set:function(k){isFinite(k)&&(this.qe=k)}});Object.defineProperty(this,"strokeStyle",
{enumerable:!0,get:function(){return d(this.Xd)},set:function(k){"string"===typeof k?this.Xd=h(k):k.me&&(this.Xd=k)}});this.arc=function(k,n,w,A,B,D){P(this.Hd,k,n,w,w,0,A,B,D)};this.arcTo=function(k,n,w,A,B){L(this.Hd,k,n,w,A,B)};this.beginPath=function(){this.Hd.delete();this.Hd=new a.Path};this.bezierCurveTo=function(k,n,w,A,B,D){var I=this.Hd;e([k,n,w,A,B,D])&&(I.isEmpty()&&I.moveTo(k,n),I.cubicTo(k,n,w,A,B,D))};this.clearRect=function(k,n,w,A){this.Fd.setStyle(a.PaintStyle.Fill);this.Fd.setBlendMode(a.BlendMode.Clear);
this.Dd.drawRect(a.XYWHRect(k,n,w,A),this.Fd);this.Fd.setBlendMode(this.Ed)};this.clip=function(k,n){"string"===typeof k?(n=k,k=this.Hd):k&&k.Te&&(k=k.Ld);k||(k=this.Hd);k=k.copy();n&&"evenodd"===n.toLowerCase()?k.setFillType(a.FillType.EvenOdd):k.setFillType(a.FillType.Winding);this.Dd.clipPath(k,a.ClipOp.Intersect,!0);k.delete()};this.closePath=function(){N(this.Hd)};this.createImageData=function(){if(1===arguments.length){var k=arguments[0];return new C(new Uint8ClampedArray(4*k.width*k.height),
k.width,k.height)}if(2===arguments.length){k=arguments[0];var n=arguments[1];return new C(new Uint8ClampedArray(4*k*n),k,n)}throw"createImageData expects 1 or 2 arguments, got "+arguments.length;};this.createLinearGradient=function(k,n,w,A){if(e(arguments)){var B=new H(k,n,w,A);this.ue.push(B);return B}};this.createPattern=function(k,n){k=new Ca(k,n);this.ue.push(k);return k};this.createRadialGradient=function(k,n,w,A,B,D){if(e(arguments)){var I=new Da(k,n,w,A,B,D);this.ue.push(I);return I}};this.drawImage=
function(k){var n=this.Je();if(3===arguments.length||5===arguments.length)var w=a.XYWHRect(arguments[1],arguments[2],arguments[3]||k.width(),arguments[4]||k.height()),A=a.XYWHRect(0,0,k.width(),k.height());else if(9===arguments.length)w=a.XYWHRect(arguments[5],arguments[6],arguments[7],arguments[8]),A=a.XYWHRect(arguments[1],arguments[2],arguments[3],arguments[4]);else throw"invalid number of args for drawImage, need 3, 5, or 9; got "+arguments.length;this.Dd.drawImageRect(k,A,w,n,!1);n.dispose()};
this.ellipse=function(k,n,w,A,B,D,I,U){P(this.Hd,k,n,w,A,B,D,I,U)};this.Je=function(){var k=this.Fd.copy();k.setStyle(a.PaintStyle.Fill);if(f(this.Sd)){var n=a.multiplyByAlpha(this.Sd,this.$d);k.setColor(n)}else n=this.Sd.me(this.Id),k.setColor(a.Color(0,0,0,this.$d)),k.setShader(n);k.dispose=function(){this.delete()};return k};this.fill=function(k,n){"string"===typeof k?(n=k,k=this.Hd):k&&k.Te&&(k=k.Ld);if("evenodd"===n)this.Hd.setFillType(a.FillType.EvenOdd);else{if("nonzero"!==n&&n)throw"invalid fill rule";
this.Hd.setFillType(a.FillType.Winding)}k||(k=this.Hd);n=this.Je();var w=this.re(n);w&&(this.Dd.save(),this.je(),this.Dd.drawPath(k,w),this.Dd.restore(),w.dispose());this.Dd.drawPath(k,n);n.dispose()};this.fillRect=function(k,n,w,A){var B=this.Je(),D=this.re(B);D&&(this.Dd.save(),this.je(),this.Dd.drawRect(a.XYWHRect(k,n,w,A),D),this.Dd.restore(),D.dispose());this.Dd.drawRect(a.XYWHRect(k,n,w,A),B);B.dispose()};this.fillText=function(k,n,w){var A=this.Je();k=a.TextBlob.MakeFromText(k,this.le);var B=
this.re(A);B&&(this.Dd.save(),this.je(),this.Dd.drawTextBlob(k,n,w,B),this.Dd.restore(),B.dispose());this.Dd.drawTextBlob(k,n,w,A);k.delete();A.dispose()};this.getImageData=function(k,n,w,A){return(k=this.Dd.readPixels(k,n,{width:w,height:A,colorType:a.ColorType.RGBA_8888,alphaType:a.AlphaType.Unpremul,colorSpace:a.ColorSpace.SRGB}))?new C(new Uint8ClampedArray(k.buffer),w,A):null};this.getLineDash=function(){return this.ne.slice()};this.ff=function(k){var n=a.Matrix.invert(this.Id);a.Matrix.mapPoints(n,
k);return k};this.isPointInPath=function(k,n,w){var A=arguments;if(3===A.length)var B=this.Hd;else if(4===A.length)B=A[0],k=A[1],n=A[2],w=A[3];else throw"invalid arg count, need 3 or 4, got "+A.length;if(!isFinite(k)||!isFinite(n))return!1;w=w||"nonzero";if("nonzero"!==w&&"evenodd"!==w)return!1;A=this.ff([k,n]);k=A[0];n=A[1];B.setFillType("nonzero"===w?a.FillType.Winding:a.FillType.EvenOdd);return B.contains(k,n)};this.isPointInStroke=function(k,n){var w=arguments;if(2===w.length)var A=this.Hd;else if(3===
w.length)A=w[0],k=w[1],n=w[2];else throw"invalid arg count, need 2 or 3, got "+w.length;if(!isFinite(k)||!isFinite(n))return!1;w=this.ff([k,n]);k=w[0];n=w[1];A=A.copy();A.setFillType(a.FillType.Winding);A.stroke({width:this.lineWidth,miter_limit:this.miterLimit,cap:this.Fd.getStrokeCap(),join:this.Fd.getStrokeJoin(),precision:.3});w=A.contains(k,n);A.delete();return w};this.lineTo=function(k,n){ea(this.Hd,k,n)};this.measureText=function(){throw Error("Clients wishing to properly measure text should use the Paragraph API");
};this.moveTo=function(k,n){var w=this.Hd;e([k,n])&&w.moveTo(k,n)};this.putImageData=function(k,n,w,A,B,D,I){if(e([n,w,A,B,D,I]))if(void 0===A)this.Dd.writePixels(k.data,k.width,k.height,n,w);else if(A=A||0,B=B||0,D=D||k.width,I=I||k.height,0>D&&(A+=D,D=Math.abs(D)),0>I&&(B+=I,I=Math.abs(I)),0>A&&(D+=A,A=0),0>B&&(I+=B,B=0),!(0>=D||0>=I)){k=a.MakeImage({width:k.width,height:k.height,alphaType:a.AlphaType.Unpremul,colorType:a.ColorType.RGBA_8888,colorSpace:a.ColorSpace.SRGB},k.data,4*k.width);var U=
a.XYWHRect(A,B,D,I);n=a.XYWHRect(n+A,w+B,D,I);w=a.Matrix.invert(this.Id);this.Dd.save();this.Dd.concat(w);this.Dd.drawImageRect(k,U,n,null,!1);this.Dd.restore();k.delete()}};this.quadraticCurveTo=function(k,n,w,A){var B=this.Hd;e([k,n,w,A])&&(B.isEmpty()&&B.moveTo(k,n),B.quadTo(k,n,w,A))};this.rect=function(k,n,w,A){var B=this.Hd;k=a.XYWHRect(k,n,w,A);e(k)&&B.addRect(k)};this.resetTransform=function(){this.Hd.transform(this.Id);var k=a.Matrix.invert(this.Id);this.Dd.concat(k);this.Id=this.Dd.getTotalMatrix()};
this.restore=function(){var k=this.ef.pop();if(k){var n=a.Matrix.multiply(this.Id,a.Matrix.invert(k.wf));this.Hd.transform(n);this.Fd.delete();this.Fd=k.Pf;this.ne=k.Nf;this.Ee=k.bg;this.Xd=k.ag;this.Sd=k.fs;this.pe=k.Zf;this.qe=k.$f;this.oe=k.Tf;this.De=k.Yf;this.$d=k.Cf;this.Ed=k.Df;this.Ce=k.Of;this.Ke=k.Bf;this.Dd.restore();this.Id=this.Dd.getTotalMatrix()}};this.rotate=function(k){if(isFinite(k)){var n=a.Matrix.rotated(-k);this.Hd.transform(n);this.Dd.rotate(k/Math.PI*180,0,0);this.Id=this.Dd.getTotalMatrix()}};
this.save=function(){if(this.Sd.ke){var k=this.Sd.ke();this.ue.push(k)}else k=this.Sd;if(this.Xd.ke){var n=this.Xd.ke();this.ue.push(n)}else n=this.Xd;this.ef.push({wf:this.Id.slice(),Nf:this.ne.slice(),bg:this.Ee,ag:n,fs:k,Zf:this.pe,$f:this.qe,Tf:this.oe,Yf:this.De,Cf:this.$d,Of:this.Ce,Df:this.Ed,Pf:this.Fd.copy(),Bf:this.Ke});this.Dd.save()};this.scale=function(k,n){if(e(arguments)){var w=a.Matrix.scaled(1/k,1/n);this.Hd.transform(w);this.Dd.scale(k,n);this.Id=this.Dd.getTotalMatrix()}};this.setLineDash=
function(k){for(var n=0;n<k.length;n++)if(!isFinite(k[n])||0>k[n])return;1===k.length%2&&Array.prototype.push.apply(k,k);this.ne=k};this.setTransform=function(k,n,w,A,B,D){e(arguments)&&(this.resetTransform(),this.transform(k,n,w,A,B,D))};this.je=function(){var k=a.Matrix.invert(this.Id);this.Dd.concat(k);this.Dd.concat(a.Matrix.translated(this.pe,this.qe));this.Dd.concat(this.Id)};this.re=function(k){var n=a.multiplyByAlpha(this.De,this.$d);if(!a.getColorComponents(n)[3]||!(this.oe||this.qe||this.pe))return null;
k=k.copy();k.setColor(n);var w=a.MaskFilter.MakeBlur(a.BlurStyle.Normal,this.oe/2,!1);k.setMaskFilter(w);k.dispose=function(){w.delete();this.delete()};return k};this.Ve=function(){var k=this.Fd.copy();k.setStyle(a.PaintStyle.Stroke);if(f(this.Xd)){var n=a.multiplyByAlpha(this.Xd,this.$d);k.setColor(n)}else n=this.Xd.me(this.Id),k.setColor(a.Color(0,0,0,this.$d)),k.setShader(n);k.setStrokeWidth(this.Ee);if(this.ne.length){var w=a.PathEffect.MakeDash(this.ne,this.Ce);k.setPathEffect(w)}k.dispose=function(){w&&
w.delete();this.delete()};return k};this.stroke=function(k){k=k?k.Ld:this.Hd;var n=this.Ve(),w=this.re(n);w&&(this.Dd.save(),this.je(),this.Dd.drawPath(k,w),this.Dd.restore(),w.dispose());this.Dd.drawPath(k,n);n.dispose()};this.strokeRect=function(k,n,w,A){var B=this.Ve(),D=this.re(B);D&&(this.Dd.save(),this.je(),this.Dd.drawRect(a.XYWHRect(k,n,w,A),D),this.Dd.restore(),D.dispose());this.Dd.drawRect(a.XYWHRect(k,n,w,A),B);B.dispose()};this.strokeText=function(k,n,w){var A=this.Ve();k=a.TextBlob.MakeFromText(k,
this.le);var B=this.re(A);B&&(this.Dd.save(),this.je(),this.Dd.drawTextBlob(k,n,w,B),this.Dd.restore(),B.dispose());this.Dd.drawTextBlob(k,n,w,A);k.delete();A.dispose()};this.translate=function(k,n){if(e(arguments)){var w=a.Matrix.translated(-k,-n);this.Hd.transform(w);this.Dd.translate(k,n);this.Id=this.Dd.getTotalMatrix()}};this.transform=function(k,n,w,A,B,D){k=[k,w,B,n,A,D,0,0,1];n=a.Matrix.invert(k);this.Hd.transform(n);this.Dd.concat(k);this.Id=this.Dd.getTotalMatrix()};this.addHitRegion=function(){};
this.clearHitRegions=function(){};this.drawFocusIfNeeded=function(){};this.removeHitRegion=function(){};this.scrollPathIntoView=function(){};Object.defineProperty(this,"canvas",{value:null,writable:!1})}function z(E){this.We=E;this.de=new r(E.getCanvas());this.Le=[];this.qf=a.FontMgr.RefDefault();this.decodeImage=function(k){k=a.MakeImageFromEncoded(k);if(!k)throw"Invalid input";this.Le.push(k);return k};this.loadFont=function(k,n){k=this.qf.MakeTypefaceFromData(k);if(!k)return null;this.Le.push(k);
var w=(n.style||"normal")+"|"+(n.variant||"normal")+"|"+(n.weight||"normal");n=n.family;pa[n]||(pa[n]={"*":k});pa[n][w]=k};this.makePath2D=function(k){k=new oa(k);this.Le.push(k.Ld);return k};this.getContext=function(k){return"2d"===k?this.de:null};this.toDataURL=function(k,n){this.We.flush();var w=this.We.makeImageSnapshot();if(w){k=k||"image/png";var A=a.ImageFormat.PNG;"image/jpeg"===k&&(A=a.ImageFormat.JPEG);if(n=w.encodeToBytes(A,n||.92)){w.delete();k="data:"+k+";base64,";if(wd)n=Buffer.from(n).toString("base64");
else{w=0;A=n.length;for(var B="",D;w<A;)D=n.slice(w,Math.min(w+32768,A)),B+=String.fromCharCode.apply(null,D),w+=32768;n=btoa(B)}return k+n}}};this.dispose=function(){this.de.be();this.Le.forEach(function(k){k.delete()});this.We.dispose()}}function C(E,k,n){if(!k||0===n)throw"invalid dimensions, width and height must be non-zero";if(E.length%4)throw"arr must be a multiple of 4";n=n||E.length/(4*k);Object.defineProperty(this,"data",{value:E,writable:!1});Object.defineProperty(this,"height",{value:n,
writable:!1});Object.defineProperty(this,"width",{value:k,writable:!1})}function H(E,k,n,w){this.Nd=null;this.Td=[];this.Pd=[];this.addColorStop=function(A,B){if(0>A||1<A||!isFinite(A))throw"offset must be between 0 and 1 inclusively";B=h(B);var D=this.Pd.indexOf(A);if(-1!==D)this.Td[D]=B;else{for(D=0;D<this.Pd.length&&!(this.Pd[D]>A);D++);this.Pd.splice(D,0,A);this.Td.splice(D,0,B)}};this.ke=function(){var A=new H(E,k,n,w);A.Td=this.Td.slice();A.Pd=this.Pd.slice();return A};this.be=function(){this.Nd&&
(this.Nd.delete(),this.Nd=null)};this.me=function(A){var B=[E,k,n,w];a.Matrix.mapPoints(A,B);A=B[0];var D=B[1],I=B[2];B=B[3];this.be();return this.Nd=a.Shader.MakeLinearGradient([A,D],[I,B],this.Td,this.Pd,a.TileMode.Clamp)}}function L(E,k,n,w,A,B){if(e([k,n,w,A,B])){if(0>B)throw"radii cannot be negative";E.isEmpty()&&E.moveTo(k,n);E.arcToTangent(k,n,w,A,B)}}function N(E){if(!E.isEmpty()){var k=E.getBounds();(k[3]-k[1]||k[2]-k[0])&&E.close()}}function R(E,k,n,w,A,B,D){D=(D-B)/Math.PI*180;B=B/Math.PI*
180;k=a.LTRBRect(k-w,n-A,k+w,n+A);1E-5>Math.abs(Math.abs(D)-360)?(n=D/2,E.arcToOval(k,B,n,!1),E.arcToOval(k,B+n,n,!1)):E.arcToOval(k,B,D,!1)}function P(E,k,n,w,A,B,D,I,U){if(e([k,n,w,A,B,D,I])){if(0>w||0>A)throw"radii cannot be negative";var na=2*Math.PI,ca=D%na;0>ca&&(ca+=na);var ya=ca-D;D=ca;I+=ya;!U&&I-D>=na?I=D+na:U&&D-I>=na?I=D-na:!U&&D>I?I=D+(na-(D-I)%na):U&&D<I&&(I=D-(na-(I-D)%na));B?(U=a.Matrix.rotated(B,k,n),B=a.Matrix.rotated(-B,k,n),E.transform(B),R(E,k,n,w,A,D,I),E.transform(U)):R(E,k,
n,w,A,D,I)}}function ea(E,k,n){e([k,n])&&(E.isEmpty()&&E.moveTo(k,n),E.lineTo(k,n))}function oa(E){this.Ld=null;this.Ld="string"===typeof E?a.Path.MakeFromSVGString(E):E&&E.Te?E.Ld.copy():new a.Path;this.Te=function(){return this.Ld};this.addPath=function(k,n){n||(n={a:1,c:0,e:0,b:0,d:1,f:0});this.Ld.addPath(k.Ld,[n.a,n.c,n.e,n.b,n.d,n.f])};this.arc=function(k,n,w,A,B,D){P(this.Ld,k,n,w,w,0,A,B,D)};this.arcTo=function(k,n,w,A,B){L(this.Ld,k,n,w,A,B)};this.bezierCurveTo=function(k,n,w,A,B,D){var I=
this.Ld;e([k,n,w,A,B,D])&&(I.isEmpty()&&I.moveTo(k,n),I.cubicTo(k,n,w,A,B,D))};this.closePath=function(){N(this.Ld)};this.ellipse=function(k,n,w,A,B,D,I,U){P(this.Ld,k,n,w,A,B,D,I,U)};this.lineTo=function(k,n){ea(this.Ld,k,n)};this.moveTo=function(k,n){var w=this.Ld;e([k,n])&&w.moveTo(k,n)};this.quadraticCurveTo=function(k,n,w,A){var B=this.Ld;e([k,n,w,A])&&(B.isEmpty()&&B.moveTo(k,n),B.quadTo(k,n,w,A))};this.rect=function(k,n,w,A){var B=this.Ld;k=a.XYWHRect(k,n,w,A);e(k)&&B.addRect(k)}}function Ca(E,
k){this.Nd=null;this.sf=E;this._transform=a.Matrix.identity();""===k&&(k="repeat");switch(k){case "repeat-x":this.se=a.TileMode.Repeat;this.te=a.TileMode.Decal;break;case "repeat-y":this.se=a.TileMode.Decal;this.te=a.TileMode.Repeat;break;case "repeat":this.te=this.se=a.TileMode.Repeat;break;case "no-repeat":this.te=this.se=a.TileMode.Decal;break;default:throw"invalid repetition mode "+k;}this.setTransform=function(n){n=[n.a,n.c,n.e,n.b,n.d,n.f,0,0,1];e(n)&&(this._transform=n)};this.ke=function(){var n=
new Ca;n.se=this.se;n.te=this.te;return n};this.be=function(){this.Nd&&(this.Nd.delete(),this.Nd=null)};this.me=function(){this.be();return this.Nd=this.sf.makeShaderCubic(this.se,this.te,1/3,1/3,this._transform)}}function Da(E,k,n,w,A,B){this.Nd=null;this.Td=[];this.Pd=[];this.addColorStop=function(D,I){if(0>D||1<D||!isFinite(D))throw"offset must be between 0 and 1 inclusively";I=h(I);var U=this.Pd.indexOf(D);if(-1!==U)this.Td[U]=I;else{for(U=0;U<this.Pd.length&&!(this.Pd[U]>D);U++);this.Pd.splice(U,
0,D);this.Td.splice(U,0,I)}};this.ke=function(){var D=new Da(E,k,n,w,A,B);D.Td=this.Td.slice();D.Pd=this.Pd.slice();return D};this.be=function(){this.Nd&&(this.Nd.delete(),this.Nd=null)};this.me=function(D){var I=[E,k,w,A];a.Matrix.mapPoints(D,I);var U=I[0],na=I[1],ca=I[2];I=I[3];var ya=(Math.abs(D[0])+Math.abs(D[4]))/2;D=n*ya;ya*=B;this.be();return this.Nd=a.Shader.MakeTwoPointConicalGradient([U,na],D,[ca,I],ya,this.Td,this.Pd,a.TileMode.Clamp)}}a._testing={};var Ja={aliceblue:Float32Array.of(.941,
.973,1,1),antiquewhite:Float32Array.of(.98,.922,.843,1),aqua:Float32Array.of(0,1,1,1),aquamarine:Float32Array.of(.498,1,.831,1),azure:Float32Array.of(.941,1,1,1),beige:Float32Array.of(.961,.961,.863,1),bisque:Float32Array.of(1,.894,.769,1),black:Float32Array.of(0,0,0,1),blanchedalmond:Float32Array.of(1,.922,.804,1),blue:Float32Array.of(0,0,1,1),blueviolet:Float32Array.of(.541,.169,.886,1),brown:Float32Array.of(.647,.165,.165,1),burlywood:Float32Array.of(.871,.722,.529,1),cadetblue:Float32Array.of(.373,
.62,.627,1),chartreuse:Float32Array.of(.498,1,0,1),chocolate:Float32Array.of(.824,.412,.118,1),coral:Float32Array.of(1,.498,.314,1),cornflowerblue:Float32Array.of(.392,.584,.929,1),cornsilk:Float32Array.of(1,.973,.863,1),crimson:Float32Array.of(.863,.078,.235,1),cyan:Float32Array.of(0,1,1,1),darkblue:Float32Array.of(0,0,.545,1),darkcyan:Float32Array.of(0,.545,.545,1),darkgoldenrod:Float32Array.of(.722,.525,.043,1),darkgray:Float32Array.of(.663,.663,.663,1),darkgreen:Float32Array.of(0,.392,0,1),darkgrey:Float32Array.of(.663,
.663,.663,1),darkkhaki:Float32Array.of(.741,.718,.42,1),darkmagenta:Float32Array.of(.545,0,.545,1),darkolivegreen:Float32Array.of(.333,.42,.184,1),darkorange:Float32Array.of(1,.549,0,1),darkorchid:Float32Array.of(.6,.196,.8,1),darkred:Float32Array.of(.545,0,0,1),darksalmon:Float32Array.of(.914,.588,.478,1),darkseagreen:Float32Array.of(.561,.737,.561,1),darkslateblue:Float32Array.of(.282,.239,.545,1),darkslategray:Float32Array.of(.184,.31,.31,1),darkslategrey:Float32Array.of(.184,.31,.31,1),darkturquoise:Float32Array.of(0,
.808,.82,1),darkviolet:Float32Array.of(.58,0,.827,1),deeppink:Float32Array.of(1,.078,.576,1),deepskyblue:Float32Array.of(0,.749,1,1),dimgray:Float32Array.of(.412,.412,.412,1),dimgrey:Float32Array.of(.412,.412,.412,1),dodgerblue:Float32Array.of(.118,.565,1,1),firebrick:Float32Array.of(.698,.133,.133,1),floralwhite:Float32Array.of(1,.98,.941,1),forestgreen:Float32Array.of(.133,.545,.133,1),fuchsia:Float32Array.of(1,0,1,1),gainsboro:Float32Array.of(.863,.863,.863,1),ghostwhite:Float32Array.of(.973,.973,
1,1),gold:Float32Array.of(1,.843,0,1),goldenrod:Float32Array.of(.855,.647,.125,1),gray:Float32Array.of(.502,.502,.502,1),green:Float32Array.of(0,.502,0,1),greenyellow:Float32Array.of(.678,1,.184,1),grey:Float32Array.of(.502,.502,.502,1),honeydew:Float32Array.of(.941,1,.941,1),hotpink:Float32Array.of(1,.412,.706,1),indianred:Float32Array.of(.804,.361,.361,1),indigo:Float32Array.of(.294,0,.51,1),ivory:Float32Array.of(1,1,.941,1),khaki:Float32Array.of(.941,.902,.549,1),lavender:Float32Array.of(.902,
.902,.98,1),lavenderblush:Float32Array.of(1,.941,.961,1),lawngreen:Float32Array.of(.486,.988,0,1),lemonchiffon:Float32Array.of(1,.98,.804,1),lightblue:Float32Array.of(.678,.847,.902,1),lightcoral:Float32Array.of(.941,.502,.502,1),lightcyan:Float32Array.of(.878,1,1,1),lightgoldenrodyellow:Float32Array.of(.98,.98,.824,1),lightgray:Float32Array.of(.827,.827,.827,1),lightgreen:Float32Array.of(.565,.933,.565,1),lightgrey:Float32Array.of(.827,.827,.827,1),lightpink:Float32Array.of(1,.714,.757,1),lightsalmon:Float32Array.of(1,
.627,.478,1),lightseagreen:Float32Array.of(.125,.698,.667,1),lightskyblue:Float32Array.of(.529,.808,.98,1),lightslategray:Float32Array.of(.467,.533,.6,1),lightslategrey:Float32Array.of(.467,.533,.6,1),lightsteelblue:Float32Array.of(.69,.769,.871,1),lightyellow:Float32Array.of(1,1,.878,1),lime:Float32Array.of(0,1,0,1),limegreen:Float32Array.of(.196,.804,.196,1),linen:Float32Array.of(.98,.941,.902,1),magenta:Float32Array.of(1,0,1,1),maroon:Float32Array.of(.502,0,0,1),mediumaquamarine:Float32Array.of(.4,
.804,.667,1),mediumblue:Float32Array.of(0,0,.804,1),mediumorchid:Float32Array.of(.729,.333,.827,1),mediumpurple:Float32Array.of(.576,.439,.859,1),mediumseagreen:Float32Array.of(.235,.702,.443,1),mediumslateblue:Float32Array.of(.482,.408,.933,1),mediumspringgreen:Float32Array.of(0,.98,.604,1),mediumturquoise:Float32Array.of(.282,.82,.8,1),mediumvioletred:Float32Array.of(.78,.082,.522,1),midnightblue:Float32Array.of(.098,.098,.439,1),mintcream:Float32Array.of(.961,1,.98,1),mistyrose:Float32Array.of(1,
.894,.882,1),moccasin:Float32Array.of(1,.894,.71,1),navajowhite:Float32Array.of(1,.871,.678,1),navy:Float32Array.of(0,0,.502,1),oldlace:Float32Array.of(.992,.961,.902,1),olive:Float32Array.of(.502,.502,0,1),olivedrab:Float32Array.of(.42,.557,.137,1),orange:Float32Array.of(1,.647,0,1),orangered:Float32Array.of(1,.271,0,1),orchid:Float32Array.of(.855,.439,.839,1),palegoldenrod:Float32Array.of(.933,.91,.667,1),palegreen:Float32Array.of(.596,.984,.596,1),paleturquoise:Float32Array.of(.686,.933,.933,1),
palevioletred:Float32Array.of(.859,.439,.576,1),papayawhip:Float32Array.of(1,.937,.835,1),peachpuff:Float32Array.of(1,.855,.725,1),peru:Float32Array.of(.804,.522,.247,1),pink:Float32Array.of(1,.753,.796,1),plum:Float32Array.of(.867,.627,.867,1),powderblue:Float32Array.of(.69,.878,.902,1),purple:Float32Array.of(.502,0,.502,1),rebeccapurple:Float32Array.of(.4,.2,.6,1),red:Float32Array.of(1,0,0,1),rosybrown:Float32Array.of(.737,.561,.561,1),royalblue:Float32Array.of(.255,.412,.882,1),saddlebrown:Float32Array.of(.545,
.271,.075,1),salmon:Float32Array.of(.98,.502,.447,1),sandybrown:Float32Array.of(.957,.643,.376,1),seagreen:Float32Array.of(.18,.545,.341,1),seashell:Float32Array.of(1,.961,.933,1),sienna:Float32Array.of(.627,.322,.176,1),silver:Float32Array.of(.753,.753,.753,1),skyblue:Float32Array.of(.529,.808,.922,1),slateblue:Float32Array.of(.416,.353,.804,1),slategray:Float32Array.of(.439,.502,.565,1),slategrey:Float32Array.of(.439,.502,.565,1),snow:Float32Array.of(1,.98,.98,1),springgreen:Float32Array.of(0,1,
.498,1),steelblue:Float32Array.of(.275,.51,.706,1),tan:Float32Array.of(.824,.706,.549,1),teal:Float32Array.of(0,.502,.502,1),thistle:Float32Array.of(.847,.749,.847,1),tomato:Float32Array.of(1,.388,.278,1),transparent:Float32Array.of(0,0,0,0),turquoise:Float32Array.of(.251,.878,.816,1),violet:Float32Array.of(.933,.51,.933,1),wheat:Float32Array.of(.961,.871,.702,1),white:Float32Array.of(1,1,1,1),whitesmoke:Float32Array.of(.961,.961,.961,1),yellow:Float32Array.of(1,1,0,1),yellowgreen:Float32Array.of(.604,
.804,.196,1)};a._testing.parseColor=h;a._testing.colorToString=d;var Ka=RegExp("(italic|oblique|normal|)\\s*(small-caps|normal|)\\s*(bold|bolder|lighter|[1-9]00|normal|)\\s*([\\d\\.]+)(px|pt|pc|in|cm|mm|%|em|ex|ch|rem|q)(.+)"),pa={"Noto Mono":{"*":null},monospace:{"*":null}};a._testing.parseFontString=l;a.MakeCanvas=function(E,k){return(E=a.MakeSurface(E,k))?new z(E):null};a.ImageData=function(){if(2===arguments.length){var E=arguments[0],k=arguments[1];return new C(new Uint8ClampedArray(4*E*k),E,
k)}if(3===arguments.length){var n=arguments[0];if(n.prototype.constructor!==Uint8ClampedArray)throw"bytes must be given as a Uint8ClampedArray";E=arguments[1];k=arguments[2];if(n%4)throw"bytes must be given in a multiple of 4";if(n%E)throw"bytes must divide evenly by width";if(k&&k!==n/(4*E))throw"invalid height given";return new C(n,E,n/(4*E))}throw"invalid number of arguments - takes 2 or 3, saw "+arguments.length;}})()})(t);
var qa=Object.assign({},t),ra="./this.program",sa=(a,b)=>{throw b;},ta="object"==typeof window,ua="function"==typeof importScripts,va="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,wa="",za,Aa,Ba;
if(va){var fs=require("fs"),Ea=require("path");wa=ua?Ea.dirname(wa)+"/":__dirname+"/";za=(a,b)=>{a=a.startsWith("file://")?new URL(a):Ea.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")};Ba=a=>{a=za(a,!0);a.buffer||(a=new Uint8Array(a));return a};Aa=(a,b,c)=>{a=a.startsWith("file://")?new URL(a):Ea.normalize(a);fs.readFile(a,function(f,g){f?c(f):b(g.buffer)})};1<process.argv.length&&(ra=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);process.on("uncaughtException",function(a){if(!(a instanceof
Fa))throw a;});if(15>process.version.match(/^v(\d+)\./)[1])process.on("unhandledRejection",function(a){throw a;});sa=(a,b)=>{if(noExitRuntime)throw process.exitCode=a,b;b instanceof Fa||Ga("exiting due to exception: "+b);process.exit(a)};t.inspect=function(){return"[Emscripten Module object]"}}else if(ta||ua)ua?wa=self.location.href:"undefined"!=typeof document&&document.currentScript&&(wa=document.currentScript.src),_scriptDir&&(wa=_scriptDir),0!==wa.indexOf("blob:")?wa=wa.substr(0,wa.replace(/[?#].*/,
"").lastIndexOf("/")+1):wa="",za=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},ua&&(Ba=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),Aa=(a,b,c)=>{var f=new XMLHttpRequest;f.open("GET",a,!0);f.responseType="arraybuffer";f.onload=()=>{200==f.status||0==f.status&&f.response?b(f.response):c()};f.onerror=c;f.send(null)};var La=t.print||console.log.bind(console),Ga=t.printErr||console.warn.bind(console);
Object.assign(t,qa);qa=null;t.thisProgram&&(ra=t.thisProgram);t.quit&&(sa=t.quit);var Ma;t.wasmBinary&&(Ma=t.wasmBinary);var noExitRuntime=t.noExitRuntime||!0;"object"!=typeof WebAssembly&&Na("no native wasm support detected");var Oa,Pa=!1,Ra="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0;
function Sa(a,b,c){var f=b+c;for(c=b;a[c]&&!(c>=f);)++c;if(16<c-b&&a.buffer&&Ra)return Ra.decode(a.subarray(b,c));for(f="";b<c;){var g=a[b++];if(g&128){var m=a[b++]&63;if(192==(g&224))f+=String.fromCharCode((g&31)<<6|m);else{var q=a[b++]&63;g=224==(g&240)?(g&15)<<12|m<<6|q:(g&7)<<18|m<<12|q<<6|a[b++]&63;65536>g?f+=String.fromCharCode(g):(g-=65536,f+=String.fromCharCode(55296|g>>10,56320|g&1023))}}else f+=String.fromCharCode(g)}return f}function Ta(a,b){return a?Sa(F,a,b):""}
function la(a,b,c,f){if(!(0<f))return 0;var g=c;f=c+f-1;for(var m=0;m<a.length;++m){var q=a.charCodeAt(m);if(55296<=q&&57343>=q){var p=a.charCodeAt(++m);q=65536+((q&1023)<<10)|p&1023}if(127>=q){if(c>=f)break;b[c++]=q}else{if(2047>=q){if(c+1>=f)break;b[c++]=192|q>>6}else{if(65535>=q){if(c+2>=f)break;b[c++]=224|q>>12}else{if(c+3>=f)break;b[c++]=240|q>>18;b[c++]=128|q>>12&63}b[c++]=128|q>>6&63}b[c++]=128|q&63}}b[c]=0;return c-g}
function ka(a){for(var b=0,c=0;c<a.length;++c){var f=a.charCodeAt(c);127>=f?b++:2047>=f?b+=2:55296<=f&&57343>=f?(b+=4,++c):b+=3}return b}var Ua,F,Va,Wa,M,Xa,S,Ya;function Za(){var a=Oa.buffer;t.HEAP8=Ua=new Int8Array(a);t.HEAP16=Va=new Int16Array(a);t.HEAP32=M=new Int32Array(a);t.HEAPU8=F=new Uint8Array(a);t.HEAPU16=Wa=new Uint16Array(a);t.HEAPU32=Xa=new Uint32Array(a);t.HEAPF32=S=new Float32Array(a);t.HEAPF64=Ya=new Float64Array(a)}var $a,ab=[],bb=[],cb=[];
function hb(){var a=t.preRun.shift();ab.unshift(a)}var ib=0,jb=null,kb=null;function Na(a){if(t.onAbort)t.onAbort(a);a="Aborted("+a+")";Ga(a);Pa=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");ba(a);throw a;}function lb(){return mb.startsWith("data:application/octet-stream;base64,")}var mb;mb="canvaskit.wasm";if(!lb()){var nb=mb;mb=t.locateFile?t.locateFile(nb,wa):wa+nb}
function ob(){var a=mb;try{if(a==mb&&Ma)return new Uint8Array(Ma);if(Ba)return Ba(a);throw"both async and sync fetching of the wasm failed";}catch(b){Na(b)}}
function pb(){if(!Ma&&(ta||ua)){if("function"==typeof fetch&&!mb.startsWith("file://"))return fetch(mb,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+mb+"'";return a.arrayBuffer()}).catch(function(){return ob()});if(Aa)return new Promise(function(a,b){Aa(mb,function(c){a(new Uint8Array(c))},b)})}return Promise.resolve().then(function(){return ob()})}
function Fa(a){this.name="ExitStatus";this.message="Program terminated with exit("+a+")";this.status=a}function qb(a){for(;0<a.length;)a.shift()(t)}var rb={};function sb(a){for(;a.length;){var b=a.pop();a.pop()(b)}}function tb(a){return this.fromWireType(M[a>>2])}var ub={},vb={},wb={};function yb(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?"_"+a:a}
function zb(a,b){a=yb(a);return(new Function("body","return function "+a+'() {\n    "use strict";    return body.apply(this, arguments);\n};\n'))(b)}function Ab(a){var b=Error,c=zb(a,function(f){this.name=a;this.message=f;f=Error(f).stack;void 0!==f&&(this.stack=this.toString()+"\n"+f.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(b.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return c}
var Bb=void 0;function Cb(a){throw new Bb(a);}function Db(a,b,c){function f(p){p=c(p);p.length!==a.length&&Cb("Mismatched type converter count");for(var x=0;x<a.length;++x)Eb(a[x],p[x])}a.forEach(function(p){wb[p]=b});var g=Array(b.length),m=[],q=0;b.forEach((p,x)=>{vb.hasOwnProperty(p)?g[x]=vb[p]:(m.push(p),ub.hasOwnProperty(p)||(ub[p]=[]),ub[p].push(()=>{g[x]=vb[p];++q;q===m.length&&f(g)}))});0===m.length&&f(g)}
function Fb(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+a);}}var Gb=void 0;function Hb(a){for(var b="";F[a];)b+=Gb[F[a++]];return b}var Ib=void 0;function T(a){throw new Ib(a);}
function Eb(a,b,c={}){if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");var f=b.name;a||T('type "'+f+'" must have a positive integer typeid pointer');if(vb.hasOwnProperty(a)){if(c.Kf)return;T("Cannot register type '"+f+"' twice")}vb[a]=b;delete wb[a];ub.hasOwnProperty(a)&&(b=ub[a],delete ub[a],b.forEach(g=>g()))}function Jb(a){T(a.Cd.Md.Gd.name+" instance already deleted")}var Kb=!1;function Lb(){}
function Mb(a){--a.count.value;0===a.count.value&&(a.Rd?a.Wd.ae(a.Rd):a.Md.Gd.ae(a.Kd))}function Nb(a,b,c){if(b===c)return a;if(void 0===c.Yd)return null;a=Nb(a,b,c.Yd);return null===a?null:c.yf(a)}var Ob={},Pb=[];function Qb(){for(;Pb.length;){var a=Pb.pop();a.Cd.xe=!1;a["delete"]()}}var Rb=void 0,Sb={};function $b(a,b){for(void 0===b&&T("ptr should not be undefined");a.Yd;)b=a.Ie(b),a=a.Yd;return Sb[b]}
function ac(a,b){b.Md&&b.Kd||Cb("makeClassHandle requires ptr and ptrType");!!b.Wd!==!!b.Rd&&Cb("Both smartPtrType and smartPtr must be specified");b.count={value:1};return bc(Object.create(a,{Cd:{value:b}}))}function bc(a){if("undefined"===typeof FinalizationRegistry)return bc=b=>b,a;Kb=new FinalizationRegistry(b=>{Mb(b.Cd)});bc=b=>{var c=b.Cd;c.Rd&&Kb.register(b,{Cd:c},b);return b};Lb=b=>{Kb.unregister(b)};return bc(a)}function cc(){}
function dc(a,b,c){if(void 0===a[b].Od){var f=a[b];a[b]=function(){a[b].Od.hasOwnProperty(arguments.length)||T("Function '"+c+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+a[b].Od+")!");return a[b].Od[arguments.length].apply(this,arguments)};a[b].Od=[];a[b].Od[f.ve]=f}}
function ec(a,b,c){t.hasOwnProperty(a)?((void 0===c||void 0!==t[a].Od&&void 0!==t[a].Od[c])&&T("Cannot register public name '"+a+"' twice"),dc(t,a,a),t.hasOwnProperty(c)&&T("Cannot register multiple overloads of a function with the same number of arguments ("+c+")!"),t[a].Od[c]=b):(t[a]=b,void 0!==c&&(t[a].ig=c))}function fc(a,b,c,f,g,m,q,p){this.name=a;this.constructor=b;this.ye=c;this.ae=f;this.Yd=g;this.Ef=m;this.Ie=q;this.yf=p;this.Rf=[]}
function gc(a,b,c){for(;b!==c;)b.Ie||T("Expected null or instance of "+c.name+", got an instance of "+b.name),a=b.Ie(a),b=b.Yd;return a}function hc(a,b){if(null===b)return this.Ye&&T("null is not a valid "+this.name),0;b.Cd||T('Cannot pass "'+ic(b)+'" as a '+this.name);b.Cd.Kd||T("Cannot pass deleted object as a pointer of type "+this.name);return gc(b.Cd.Kd,b.Cd.Md.Gd,this.Gd)}
function jc(a,b){if(null===b){this.Ye&&T("null is not a valid "+this.name);if(this.Ne){var c=this.Ze();null!==a&&a.push(this.ae,c);return c}return 0}b.Cd||T('Cannot pass "'+ic(b)+'" as a '+this.name);b.Cd.Kd||T("Cannot pass deleted object as a pointer of type "+this.name);!this.Me&&b.Cd.Md.Me&&T("Cannot convert argument of type "+(b.Cd.Wd?b.Cd.Wd.name:b.Cd.Md.name)+" to parameter type "+this.name);c=gc(b.Cd.Kd,b.Cd.Md.Gd,this.Gd);if(this.Ne)switch(void 0===b.Cd.Rd&&T("Passing raw pointer to smart pointer is illegal"),
this.Xf){case 0:b.Cd.Wd===this?c=b.Cd.Rd:T("Cannot convert argument of type "+(b.Cd.Wd?b.Cd.Wd.name:b.Cd.Md.name)+" to parameter type "+this.name);break;case 1:c=b.Cd.Rd;break;case 2:if(b.Cd.Wd===this)c=b.Cd.Rd;else{var f=b.clone();c=this.Sf(c,kc(function(){f["delete"]()}));null!==a&&a.push(this.ae,c)}break;default:T("Unsupporting sharing policy")}return c}
function lc(a,b){if(null===b)return this.Ye&&T("null is not a valid "+this.name),0;b.Cd||T('Cannot pass "'+ic(b)+'" as a '+this.name);b.Cd.Kd||T("Cannot pass deleted object as a pointer of type "+this.name);b.Cd.Md.Me&&T("Cannot convert argument of type "+b.Cd.Md.name+" to parameter type "+this.name);return gc(b.Cd.Kd,b.Cd.Md.Gd,this.Gd)}
function mc(a,b,c,f,g,m,q,p,x,v,G){this.name=a;this.Gd=b;this.Ye=c;this.Me=f;this.Ne=g;this.Qf=m;this.Xf=q;this.mf=p;this.Ze=x;this.Sf=v;this.ae=G;g||void 0!==b.Yd?this.toWireType=jc:(this.toWireType=f?hc:lc,this.Vd=null)}function nc(a,b,c){t.hasOwnProperty(a)||Cb("Replacing nonexistant public symbol");void 0!==t[a].Od&&void 0!==c?t[a].Od[c]=b:(t[a]=b,t[a].ve=c)}function oc(a){return $a.get(a)}
function pc(a,b){var c=[];return function(){c.length=0;Object.assign(c,arguments);if(a.includes("j")){var f=t["dynCall_"+a];f=c&&c.length?f.apply(null,[b].concat(c)):f.call(null,b)}else f=oc(b).apply(null,c);return f}}function qc(a,b){a=Hb(a);var c=a.includes("j")?pc(a,b):oc(b);"function"!=typeof c&&T("unknown function pointer with signature "+a+": "+b);return c}var rc=void 0;function sc(a){a=tc(a);var b=Hb(a);uc(a);return b}
function vc(a,b){function c(m){g[m]||vb[m]||(wb[m]?wb[m].forEach(c):(f.push(m),g[m]=!0))}var f=[],g={};b.forEach(c);throw new rc(a+": "+f.map(sc).join([", "]));}function wc(a){var b=Function;if(!(b instanceof Function))throw new TypeError("new_ called with constructor type "+typeof b+" which is not a function");var c=zb(b.name||"unknownFunctionName",function(){});c.prototype=b.prototype;c=new c;a=b.apply(c,a);return a instanceof Object?a:c}
function xc(a,b,c,f,g){var m=b.length;2>m&&T("argTypes array size mismatch! Must at least get return value and 'this' types!");var q=null!==b[1]&&null!==c,p=!1;for(c=1;c<b.length;++c)if(null!==b[c]&&void 0===b[c].Vd){p=!0;break}var x="void"!==b[0].name,v="",G="";for(c=0;c<m-2;++c)v+=(0!==c?", ":"")+"arg"+c,G+=(0!==c?", ":"")+"arg"+c+"Wired";a="return function "+yb(a)+"("+v+") {\nif (arguments.length !== "+(m-2)+") {\nthrowBindingError('function "+a+" called with ' + arguments.length + ' arguments, expected "+
(m-2)+" args!');\n}\n";p&&(a+="var destructors = [];\n");var J=p?"destructors":"null";v="throwBindingError invoker fn runDestructors retType classParam".split(" ");f=[T,f,g,sb,b[0],b[1]];q&&(a+="var thisWired = classParam.toWireType("+J+", this);\n");for(c=0;c<m-2;++c)a+="var arg"+c+"Wired = argType"+c+".toWireType("+J+", arg"+c+"); // "+b[c+2].name+"\n",v.push("argType"+c),f.push(b[c+2]);q&&(G="thisWired"+(0<G.length?", ":"")+G);a+=(x?"var rv = ":"")+"invoker(fn"+(0<G.length?", ":"")+G+");\n";if(p)a+=
"runDestructors(destructors);\n";else for(c=q?1:2;c<b.length;++c)m=1===c?"thisWired":"arg"+(c-2)+"Wired",null!==b[c].Vd&&(a+=m+"_dtor("+m+"); // "+b[c].name+"\n",v.push(m+"_dtor"),f.push(b[c].Vd));x&&(a+="var ret = retType.fromWireType(rv);\nreturn ret;\n");v.push(a+"}\n");return wc(v).apply(null,f)}function yc(a,b){for(var c=[],f=0;f<a;f++)c.push(Xa[b+4*f>>2]);return c}var zc=[],Ic=[{},{value:void 0},{value:null},{value:!0},{value:!1}];
function Jc(a){4<a&&0===--Ic[a].$e&&(Ic[a]=void 0,zc.push(a))}var Kc=a=>{a||T("Cannot use deleted val. handle = "+a);return Ic[a].value},kc=a=>{switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var b=zc.length?zc.pop():Ic.length;Ic[b]={$e:1,value:a};return b}};
function Lc(a,b,c){switch(b){case 0:return function(f){return this.fromWireType((c?Ua:F)[f])};case 1:return function(f){return this.fromWireType((c?Va:Wa)[f>>1])};case 2:return function(f){return this.fromWireType((c?M:Xa)[f>>2])};default:throw new TypeError("Unknown integer type: "+a);}}function Mc(a,b){var c=vb[a];void 0===c&&T(b+" has unknown type "+sc(a));return c}function ic(a){if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a}
function Nc(a,b){switch(b){case 2:return function(c){return this.fromWireType(S[c>>2])};case 3:return function(c){return this.fromWireType(Ya[c>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function Oc(a,b,c){switch(b){case 0:return c?function(f){return Ua[f]}:function(f){return F[f]};case 1:return c?function(f){return Va[f>>1]}:function(f){return Wa[f>>1]};case 2:return c?function(f){return M[f>>2]}:function(f){return Xa[f>>2]};default:throw new TypeError("Unknown integer type: "+a);}}var Pc="undefined"!=typeof TextDecoder?new TextDecoder("utf-16le"):void 0;
function Qc(a,b){var c=a>>1;for(var f=c+b/2;!(c>=f)&&Wa[c];)++c;c<<=1;if(32<c-a&&Pc)return Pc.decode(F.subarray(a,c));c="";for(f=0;!(f>=b/2);++f){var g=Va[a+2*f>>1];if(0==g)break;c+=String.fromCharCode(g)}return c}function Rc(a,b,c){void 0===c&&(c=2147483647);if(2>c)return 0;c-=2;var f=b;c=c<2*a.length?c/2:a.length;for(var g=0;g<c;++g)Va[b>>1]=a.charCodeAt(g),b+=2;Va[b>>1]=0;return b-f}function Sc(a){return 2*a.length}
function Tc(a,b){for(var c=0,f="";!(c>=b/4);){var g=M[a+4*c>>2];if(0==g)break;++c;65536<=g?(g-=65536,f+=String.fromCharCode(55296|g>>10,56320|g&1023)):f+=String.fromCharCode(g)}return f}function Uc(a,b,c){void 0===c&&(c=2147483647);if(4>c)return 0;var f=b;c=f+c-4;for(var g=0;g<a.length;++g){var m=a.charCodeAt(g);if(55296<=m&&57343>=m){var q=a.charCodeAt(++g);m=65536+((m&1023)<<10)|q&1023}M[b>>2]=m;b+=4;if(b+4>c)break}M[b>>2]=0;return b-f}
function Vc(a){for(var b=0,c=0;c<a.length;++c){var f=a.charCodeAt(c);55296<=f&&57343>=f&&++c;b+=4}return b}var Wc={};function Xc(a){var b=Wc[a];return void 0===b?Hb(a):b}var Yc=[];function Zc(a){var b=Yc.length;Yc.push(a);return b}function $c(a,b){for(var c=Array(a),f=0;f<a;++f)c[f]=Mc(Xa[b+4*f>>2],"parameter "+f);return c}var ad=[],bd;bd=va?()=>{var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:()=>performance.now();
function cd(a){var b=a.getExtension("ANGLE_instanced_arrays");b&&(a.vertexAttribDivisor=function(c,f){b.vertexAttribDivisorANGLE(c,f)},a.drawArraysInstanced=function(c,f,g,m){b.drawArraysInstancedANGLE(c,f,g,m)},a.drawElementsInstanced=function(c,f,g,m,q){b.drawElementsInstancedANGLE(c,f,g,m,q)})}
function dd(a){var b=a.getExtension("OES_vertex_array_object");b&&(a.createVertexArray=function(){return b.createVertexArrayOES()},a.deleteVertexArray=function(c){b.deleteVertexArrayOES(c)},a.bindVertexArray=function(c){b.bindVertexArrayOES(c)},a.isVertexArray=function(c){return b.isVertexArrayOES(c)})}function ed(a){var b=a.getExtension("WEBGL_draw_buffers");b&&(a.drawBuffers=function(c,f){b.drawBuffersWEBGL(c,f)})}
var fd=1,gd=[],hd=[],jd=[],kd=[],ld=[],md=[],nd=[],ja=[],od=[],pd=[],qd={},rd={},sd=4;function V(a){td||(td=a)}function ud(a){for(var b=fd++,c=a.length;c<b;c++)a[c]=null;return b}function fa(a,b){a.cf||(a.cf=a.getContext,a.getContext=function(f,g){g=a.cf(f,g);return"webgl"==f==g instanceof WebGLRenderingContext?g:null});var c=1<b.majorVersion?a.getContext("webgl2",b):a.getContext("webgl",b);return c?yd(c,b):0}
function yd(a,b){var c=ud(ja),f={Jf:c,attributes:b,version:b.majorVersion,ze:a};a.canvas&&(a.canvas.df=f);ja[c]=f;("undefined"==typeof b.zf||b.zf)&&zd(f);return c}function ia(a){u=ja[a];t.gg=W=u&&u.ze;return!(a&&!W)}
function zd(a){a||(a=u);if(!a.Lf){a.Lf=!0;var b=a.ze;cd(b);dd(b);ed(b);b.hf=b.getExtension("WEBGL_draw_instanced_base_vertex_base_instance");b.lf=b.getExtension("WEBGL_multi_draw_instanced_base_vertex_base_instance");2<=a.version&&(b.jf=b.getExtension("EXT_disjoint_timer_query_webgl2"));if(2>a.version||!b.jf)b.jf=b.getExtension("EXT_disjoint_timer_query");b.hg=b.getExtension("WEBGL_multi_draw");(b.getSupportedExtensions()||[]).forEach(function(c){c.includes("lose_context")||c.includes("debug")||b.getExtension(c)})}}
var td,u,Ad=[];function Bd(a,b,c,f){for(var g=0;g<a;g++){var m=W[c](),q=m&&ud(f);m?(m.name=q,f[q]=m):V(1282);M[b+4*g>>2]=q}}
function Cd(a,b){if(b){var c=void 0;switch(a){case 36346:c=1;break;case 36344:return;case 34814:case 36345:c=0;break;case 34466:var f=W.getParameter(34467);c=f?f.length:0;break;case 33309:if(2>u.version){V(1282);return}c=2*(W.getSupportedExtensions()||[]).length;break;case 33307:case 33308:if(2>u.version){V(1280);return}c=33307==a?3:0}if(void 0===c)switch(f=W.getParameter(a),typeof f){case "number":c=f;break;case "boolean":c=f?1:0;break;case "string":V(1280);return;case "object":if(null===f)switch(a){case 34964:case 35725:case 34965:case 36006:case 36007:case 32873:case 34229:case 36662:case 36663:case 35053:case 35055:case 36010:case 35097:case 35869:case 32874:case 36389:case 35983:case 35368:case 34068:c=
0;break;default:V(1280);return}else{if(f instanceof Float32Array||f instanceof Uint32Array||f instanceof Int32Array||f instanceof Array){for(a=0;a<f.length;++a)M[b+4*a>>2]=f[a];return}try{c=f.name|0}catch(g){V(1280);Ga("GL_INVALID_ENUM in glGet0v: Unknown object returned from WebGL getParameter("+a+")! (error: "+g+")");return}}break;default:V(1280);Ga("GL_INVALID_ENUM in glGet0v: Native code calling glGet0v("+a+") and it returns "+f+" of type "+typeof f+"!");return}M[b>>2]=c}else V(1281)}
function Dd(a){var b=ka(a)+1,c=Ed(b);la(a,F,c,b);return c}function Fd(a){return"]"==a.slice(-1)&&a.lastIndexOf("[")}function Gd(a){a-=5120;return 0==a?Ua:1==a?F:2==a?Va:4==a?M:6==a?S:5==a||28922==a||28520==a||30779==a||30782==a?Xa:Wa}function Hd(a,b,c,f,g){a=Gd(a);var m=31-Math.clz32(a.BYTES_PER_ELEMENT),q=sd;return a.subarray(g>>m,g+f*(c*({5:3,6:4,8:2,29502:3,29504:4,26917:2,26918:2,29846:3,29847:4}[b-6402]||1)*(1<<m)+q-1&-q)>>m)}
function Y(a){var b=W.xf;if(b){var c=b.He[a];"number"==typeof c&&(b.He[a]=c=W.getUniformLocation(b,b.nf[a]+(0<c?"["+c+"]":"")));return c}V(1282)}var Id=[],Jd=[],Kd={};
function Ld(){if(!Md){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ra||"./this.program"},b;for(b in Kd)void 0===Kd[b]?delete a[b]:a[b]=Kd[b];var c=[];for(b in a)c.push(b+"="+a[b]);Md=c}return Md}var Md,Nd=[null,[],[]];function Od(a){return 0===a%4&&(0!==a%100||0===a%400)}
var Pd=[31,29,31,30,31,30,31,31,30,31,30,31],Qd=[31,28,31,30,31,30,31,31,30,31,30,31];function Rd(a){var b=Array(ka(a)+1);la(a,b,0,b.length);return b}
function Sd(a,b,c,f){function g(y,K,Q){for(y="number"==typeof y?y.toString():y||"";y.length<K;)y=Q[0]+y;return y}function m(y,K){return g(y,K,"0")}function q(y,K){function Q(X){return 0>X?-1:0<X?1:0}var Z;0===(Z=Q(y.getFullYear()-K.getFullYear()))&&0===(Z=Q(y.getMonth()-K.getMonth()))&&(Z=Q(y.getDate()-K.getDate()));return Z}function p(y){switch(y.getDay()){case 0:return new Date(y.getFullYear()-1,11,29);case 1:return y;case 2:return new Date(y.getFullYear(),0,3);case 3:return new Date(y.getFullYear(),
0,2);case 4:return new Date(y.getFullYear(),0,1);case 5:return new Date(y.getFullYear()-1,11,31);case 6:return new Date(y.getFullYear()-1,11,30)}}function x(y){var K=y.fe;for(y=new Date((new Date(y.ge+1900,0,1)).getTime());0<K;){var Q=y.getMonth(),Z=(Od(y.getFullYear())?Pd:Qd)[Q];if(K>Z-y.getDate())K-=Z-y.getDate()+1,y.setDate(1),11>Q?y.setMonth(Q+1):(y.setMonth(0),y.setFullYear(y.getFullYear()+1));else{y.setDate(y.getDate()+K);break}}Q=new Date(y.getFullYear()+1,0,4);K=p(new Date(y.getFullYear(),
0,4));Q=p(Q);return 0>=q(K,y)?0>=q(Q,y)?y.getFullYear()+1:y.getFullYear():y.getFullYear()-1}var v=M[f+40>>2];f={eg:M[f>>2],dg:M[f+4>>2],Re:M[f+8>>2],af:M[f+12>>2],Se:M[f+16>>2],ge:M[f+20>>2],Zd:M[f+24>>2],fe:M[f+28>>2],kg:M[f+32>>2],cg:M[f+36>>2],fg:v?Ta(v):""};c=Ta(c);v={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y",
"%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var G in v)c=c.replace(new RegExp(G,"g"),v[G]);var J="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),O="January February March April May June July August September October November December".split(" ");v={"%a":function(y){return J[y.Zd].substring(0,3)},"%A":function(y){return J[y.Zd]},"%b":function(y){return O[y.Se].substring(0,3)},
"%B":function(y){return O[y.Se]},"%C":function(y){return m((y.ge+1900)/100|0,2)},"%d":function(y){return m(y.af,2)},"%e":function(y){return g(y.af,2," ")},"%g":function(y){return x(y).toString().substring(2)},"%G":function(y){return x(y)},"%H":function(y){return m(y.Re,2)},"%I":function(y){y=y.Re;0==y?y=12:12<y&&(y-=12);return m(y,2)},"%j":function(y){for(var K=0,Q=0;Q<=y.Se-1;K+=(Od(y.ge+1900)?Pd:Qd)[Q++]);return m(y.af+K,3)},"%m":function(y){return m(y.Se+1,2)},"%M":function(y){return m(y.dg,2)},
"%n":function(){return"\n"},"%p":function(y){return 0<=y.Re&&12>y.Re?"AM":"PM"},"%S":function(y){return m(y.eg,2)},"%t":function(){return"\t"},"%u":function(y){return y.Zd||7},"%U":function(y){return m(Math.floor((y.fe+7-y.Zd)/7),2)},"%V":function(y){var K=Math.floor((y.fe+7-(y.Zd+6)%7)/7);2>=(y.Zd+371-y.fe-2)%7&&K++;if(K)53==K&&(Q=(y.Zd+371-y.fe)%7,4==Q||3==Q&&Od(y.ge)||(K=1));else{K=52;var Q=(y.Zd+7-y.fe-1)%7;(4==Q||5==Q&&Od(y.ge%400-1))&&K++}return m(K,2)},"%w":function(y){return y.Zd},"%W":function(y){return m(Math.floor((y.fe+
7-(y.Zd+6)%7)/7),2)},"%y":function(y){return(y.ge+1900).toString().substring(2)},"%Y":function(y){return y.ge+1900},"%z":function(y){y=y.cg;var K=0<=y;y=Math.abs(y)/60;return(K?"+":"-")+String("0000"+(y/60*100+y%60)).slice(-4)},"%Z":function(y){return y.fg},"%%":function(){return"%"}};c=c.replace(/%%/g,"\x00\x00");for(G in v)c.includes(G)&&(c=c.replace(new RegExp(G,"g"),v[G](f)));c=c.replace(/\0\0/g,"%");G=Rd(c);if(G.length>b)return 0;Ua.set(G,a);return G.length-1}Bb=t.InternalError=Ab("InternalError");
for(var Td=Array(256),Ud=0;256>Ud;++Ud)Td[Ud]=String.fromCharCode(Ud);Gb=Td;Ib=t.BindingError=Ab("BindingError");cc.prototype.isAliasOf=function(a){if(!(this instanceof cc&&a instanceof cc))return!1;var b=this.Cd.Md.Gd,c=this.Cd.Kd,f=a.Cd.Md.Gd;for(a=a.Cd.Kd;b.Yd;)c=b.Ie(c),b=b.Yd;for(;f.Yd;)a=f.Ie(a),f=f.Yd;return b===f&&c===a};
cc.prototype.clone=function(){this.Cd.Kd||Jb(this);if(this.Cd.Ge)return this.Cd.count.value+=1,this;var a=bc,b=Object,c=b.create,f=Object.getPrototypeOf(this),g=this.Cd;a=a(c.call(b,f,{Cd:{value:{count:g.count,xe:g.xe,Ge:g.Ge,Kd:g.Kd,Md:g.Md,Rd:g.Rd,Wd:g.Wd}}}));a.Cd.count.value+=1;a.Cd.xe=!1;return a};cc.prototype["delete"]=function(){this.Cd.Kd||Jb(this);this.Cd.xe&&!this.Cd.Ge&&T("Object already scheduled for deletion");Lb(this);Mb(this.Cd);this.Cd.Ge||(this.Cd.Rd=void 0,this.Cd.Kd=void 0)};
cc.prototype.isDeleted=function(){return!this.Cd.Kd};cc.prototype.deleteLater=function(){this.Cd.Kd||Jb(this);this.Cd.xe&&!this.Cd.Ge&&T("Object already scheduled for deletion");Pb.push(this);1===Pb.length&&Rb&&Rb(Qb);this.Cd.xe=!0;return this};t.getInheritedInstanceCount=function(){return Object.keys(Sb).length};t.getLiveInheritedInstances=function(){var a=[],b;for(b in Sb)Sb.hasOwnProperty(b)&&a.push(Sb[b]);return a};t.flushPendingDeletes=Qb;t.setDelayFunction=function(a){Rb=a;Pb.length&&Rb&&Rb(Qb)};
mc.prototype.Ff=function(a){this.mf&&(a=this.mf(a));return a};mc.prototype.gf=function(a){this.ae&&this.ae(a)};mc.prototype.argPackAdvance=8;mc.prototype.readValueFromPointer=tb;mc.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
mc.prototype.fromWireType=function(a){function b(){return this.Ne?ac(this.Gd.ye,{Md:this.Qf,Kd:c,Wd:this,Rd:a}):ac(this.Gd.ye,{Md:this,Kd:a})}var c=this.Ff(a);if(!c)return this.gf(a),null;var f=$b(this.Gd,c);if(void 0!==f){if(0===f.Cd.count.value)return f.Cd.Kd=c,f.Cd.Rd=a,f.clone();f=f.clone();this.gf(a);return f}f=this.Gd.Ef(c);f=Ob[f];if(!f)return b.call(this);f=this.Me?f.vf:f.pointerType;var g=Nb(c,this.Gd,f.Gd);return null===g?b.call(this):this.Ne?ac(f.Gd.ye,{Md:f,Kd:g,Wd:this,Rd:a}):ac(f.Gd.ye,
{Md:f,Kd:g})};rc=t.UnboundTypeError=Ab("UnboundTypeError");t.count_emval_handles=function(){for(var a=0,b=5;b<Ic.length;++b)void 0!==Ic[b]&&++a;return a};t.get_first_emval=function(){for(var a=5;a<Ic.length;++a)if(void 0!==Ic[a])return Ic[a];return null};for(var W,Vd=0;32>Vd;++Vd)Ad.push(Array(Vd));var Wd=new Float32Array(288);for(Vd=0;288>Vd;++Vd)Id[Vd]=Wd.subarray(0,Vd+1);var Xd=new Int32Array(288);for(Vd=0;288>Vd;++Vd)Jd[Vd]=Xd.subarray(0,Vd+1);
var me={H:function(){return 0},xb:function(){},zb:function(){return 0},ub:function(){},Ab:function(){},vb:function(){},P:function(a){var b=rb[a];delete rb[a];var c=b.Ze,f=b.ae,g=b.kf,m=g.map(q=>q.If).concat(g.map(q=>q.Vf));Db([a],m,q=>{var p={};g.forEach((x,v)=>{var G=q[v],J=x.Gf,O=x.Hf,y=q[v+g.length],K=x.Uf,Q=x.Wf;p[x.Af]={read:Z=>G.fromWireType(J(O,Z)),write:(Z,X)=>{var ma=[];K(Q,Z,y.toWireType(ma,X));sb(ma)}}});return[{name:b.name,fromWireType:function(x){var v={},G;for(G in p)v[G]=p[G].read(x);
f(x);return v},toWireType:function(x,v){for(var G in p)if(!(G in v))throw new TypeError('Missing field:  "'+G+'"');var J=c();for(G in p)p[G].write(J,v[G]);null!==x&&x.push(f,J);return J},argPackAdvance:8,readValueFromPointer:tb,Vd:f}]})},kb:function(){},Cb:function(a,b,c,f,g){var m=Fb(c);b=Hb(b);Eb(a,{name:b,fromWireType:function(q){return!!q},toWireType:function(q,p){return p?f:g},argPackAdvance:8,readValueFromPointer:function(q){if(1===c)var p=Ua;else if(2===c)p=Va;else if(4===c)p=M;else throw new TypeError("Unknown boolean type size: "+
b);return this.fromWireType(p[q>>m])},Vd:null})},i:function(a,b,c,f,g,m,q,p,x,v,G,J,O){G=Hb(G);m=qc(g,m);p&&(p=qc(q,p));v&&(v=qc(x,v));O=qc(J,O);var y=yb(G);ec(y,function(){vc("Cannot construct "+G+" due to unbound types",[f])});Db([a,b,c],f?[f]:[],function(K){K=K[0];if(f){var Q=K.Gd;var Z=Q.ye}else Z=cc.prototype;K=zb(y,function(){if(Object.getPrototypeOf(this)!==X)throw new Ib("Use 'new' to construct "+G);if(void 0===ma.ee)throw new Ib(G+" has no accessible constructor");var fb=ma.ee[arguments.length];
if(void 0===fb)throw new Ib("Tried to invoke ctor of "+G+" with invalid number of parameters ("+arguments.length+") - expected ("+Object.keys(ma.ee).toString()+") parameters instead!");return fb.apply(this,arguments)});var X=Object.create(Z,{constructor:{value:K}});K.prototype=X;var ma=new fc(G,K,X,O,Q,m,p,v);Q=new mc(G,ma,!0,!1,!1);Z=new mc(G+"*",ma,!1,!1,!1);var eb=new mc(G+" const*",ma,!1,!0,!1);Ob[a]={pointerType:Z,vf:eb};nc(y,K);return[Q,Z,eb]})},g:function(a,b,c,f,g,m,q){var p=yc(c,f);b=Hb(b);
m=qc(g,m);Db([],[a],function(x){function v(){vc("Cannot call "+G+" due to unbound types",p)}x=x[0];var G=x.name+"."+b;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);var J=x.Gd.constructor;void 0===J[b]?(v.ve=c-1,J[b]=v):(dc(J,b,G),J[b].Od[c-1]=v);Db([],p,function(O){O=[O[0],null].concat(O.slice(1));O=xc(G,O,null,m,q);void 0===J[b].Od?(O.ve=c-1,J[b]=O):J[b].Od[c-1]=O;return[]});return[]})},r:function(a,b,c,f,g,m){0<b||Na();var q=yc(b,c);g=qc(f,g);Db([],[a],function(p){p=p[0];var x="constructor "+p.name;
void 0===p.Gd.ee&&(p.Gd.ee=[]);if(void 0!==p.Gd.ee[b-1])throw new Ib("Cannot register multiple constructors with identical number of parameters ("+(b-1)+") for class '"+p.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");p.Gd.ee[b-1]=()=>{vc("Cannot construct "+p.name+" due to unbound types",q)};Db([],q,function(v){v.splice(1,0,null);p.Gd.ee[b-1]=xc(x,v,null,g,m);return[]});return[]})},b:function(a,b,c,f,g,m,q,p){var x=yc(c,f);b=Hb(b);m=qc(g,
m);Db([],[a],function(v){function G(){vc("Cannot call "+J+" due to unbound types",x)}v=v[0];var J=v.name+"."+b;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);p&&v.Gd.Rf.push(b);var O=v.Gd.ye,y=O[b];void 0===y||void 0===y.Od&&y.className!==v.name&&y.ve===c-2?(G.ve=c-2,G.className=v.name,O[b]=G):(dc(O,b,J),O[b].Od[c-2]=G);Db([],x,function(K){K=xc(J,K,v,m,q);void 0===O[b].Od?(K.ve=c-2,O[b]=K):O[b].Od[c-2]=K;return[]});return[]})},O:function(a,b,c){a=Hb(a);Db([],[b],function(f){f=f[0];t[a]=f.fromWireType(c);
return[]})},Bb:function(a,b){b=Hb(b);Eb(a,{name:b,fromWireType:function(c){var f=Kc(c);Jc(c);return f},toWireType:function(c,f){return kc(f)},argPackAdvance:8,readValueFromPointer:tb,Vd:null})},k:function(a,b,c,f){function g(){}c=Fb(c);b=Hb(b);g.values={};Eb(a,{name:b,constructor:g,fromWireType:function(m){return this.constructor.values[m]},toWireType:function(m,q){return q.value},argPackAdvance:8,readValueFromPointer:Lc(b,c,f),Vd:null});ec(b,g)},c:function(a,b,c){var f=Mc(a,"enum");b=Hb(b);a=f.constructor;
f=Object.create(f.constructor.prototype,{value:{value:c},constructor:{value:zb(f.name+"_"+b,function(){})}});a.values[c]=f;a[b]=f},L:function(a,b,c){c=Fb(c);b=Hb(b);Eb(a,{name:b,fromWireType:function(f){return f},toWireType:function(f,g){return g},argPackAdvance:8,readValueFromPointer:Nc(b,c),Vd:null})},q:function(a,b,c,f,g,m){var q=yc(b,c);a=Hb(a);g=qc(f,g);ec(a,function(){vc("Cannot call "+a+" due to unbound types",q)},b-1);Db([],q,function(p){p=[p[0],null].concat(p.slice(1));nc(a,xc(a,p,null,g,
m),b-1);return[]})},s:function(a,b,c,f,g){b=Hb(b);-1===g&&(g=4294967295);g=Fb(c);var m=p=>p;if(0===f){var q=32-8*c;m=p=>p<<q>>>q}c=b.includes("unsigned")?function(p,x){return x>>>0}:function(p,x){return x};Eb(a,{name:b,fromWireType:m,toWireType:c,argPackAdvance:8,readValueFromPointer:Oc(b,g,0!==f),Vd:null})},n:function(a,b,c){function f(m){m>>=2;var q=Xa;return new g(q.buffer,q[m+1],q[m])}var g=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][b];c=Hb(c);
Eb(a,{name:c,fromWireType:f,argPackAdvance:8,readValueFromPointer:f},{Kf:!0})},o:function(a,b,c,f,g,m,q,p,x,v,G,J){c=Hb(c);m=qc(g,m);p=qc(q,p);v=qc(x,v);J=qc(G,J);Db([a],[b],function(O){O=O[0];return[new mc(c,O.Gd,!1,!1,!0,O,f,m,p,v,J)]})},K:function(a,b){b=Hb(b);var c="std::string"===b;Eb(a,{name:b,fromWireType:function(f){var g=Xa[f>>2],m=f+4;if(c)for(var q=m,p=0;p<=g;++p){var x=m+p;if(p==g||0==F[x]){q=Ta(q,x-q);if(void 0===v)var v=q;else v+=String.fromCharCode(0),v+=q;q=x+1}}else{v=Array(g);for(p=
0;p<g;++p)v[p]=String.fromCharCode(F[m+p]);v=v.join("")}uc(f);return v},toWireType:function(f,g){g instanceof ArrayBuffer&&(g=new Uint8Array(g));var m,q="string"==typeof g;q||g instanceof Uint8Array||g instanceof Uint8ClampedArray||g instanceof Int8Array||T("Cannot pass non-string to std::string");c&&q?m=ka(g):m=g.length;var p=Ed(4+m+1),x=p+4;Xa[p>>2]=m;if(c&&q)la(g,F,x,m+1);else if(q)for(q=0;q<m;++q){var v=g.charCodeAt(q);255<v&&(uc(x),T("String has UTF-16 code units that do not fit in 8 bits"));
F[x+q]=v}else for(q=0;q<m;++q)F[x+q]=g[q];null!==f&&f.push(uc,p);return p},argPackAdvance:8,readValueFromPointer:tb,Vd:function(f){uc(f)}})},B:function(a,b,c){c=Hb(c);if(2===b){var f=Qc;var g=Rc;var m=Sc;var q=()=>Wa;var p=1}else 4===b&&(f=Tc,g=Uc,m=Vc,q=()=>Xa,p=2);Eb(a,{name:c,fromWireType:function(x){for(var v=Xa[x>>2],G=q(),J,O=x+4,y=0;y<=v;++y){var K=x+4+y*b;if(y==v||0==G[K>>p])O=f(O,K-O),void 0===J?J=O:(J+=String.fromCharCode(0),J+=O),O=K+b}uc(x);return J},toWireType:function(x,v){"string"!=
typeof v&&T("Cannot pass non-string to C++ string type "+c);var G=m(v),J=Ed(4+G+b);Xa[J>>2]=G>>p;g(v,J+4,G+b);null!==x&&x.push(uc,J);return J},argPackAdvance:8,readValueFromPointer:tb,Vd:function(x){uc(x)}})},J:function(a,b,c,f,g,m){rb[a]={name:Hb(b),Ze:qc(c,f),ae:qc(g,m),kf:[]}},v:function(a,b,c,f,g,m,q,p,x,v){rb[a].kf.push({Af:Hb(b),If:c,Gf:qc(f,g),Hf:m,Vf:q,Uf:qc(p,x),Wf:v})},Db:function(a,b){b=Hb(b);Eb(a,{Mf:!0,name:b,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},rb:function(){return!0},
mb:function(){throw Infinity;},gb:function(a,b,c,f,g){a=Yc[a];b=Kc(b);c=Xc(c);var m=[];Xa[f>>2]=kc(m);return a(b,c,m,g)},w:function(a,b,c,f){a=Yc[a];b=Kc(b);c=Xc(c);a(b,c,null,f)},p:Jc,u:function(a,b){var c=$c(a,b),f=c[0];b=f.name+"_$"+c.slice(1).map(function(G){return G.name}).join("_")+"$";var g=ad[b];if(void 0!==g)return g;g=["retType"];for(var m=[f],q="",p=0;p<a-1;++p)q+=(0!==p?", ":"")+"arg"+p,g.push("argType"+p),m.push(c[1+p]);var x="return function "+yb("methodCaller_"+b)+"(handle, name, destructors, args) {\n",
v=0;for(p=0;p<a-1;++p)x+="    var arg"+p+" = argType"+p+".readValueFromPointer(args"+(v?"+"+v:"")+");\n",v+=c[p+1].argPackAdvance;x+="    var rv = handle[name]("+q+");\n";for(p=0;p<a-1;++p)c[p+1].deleteObject&&(x+="    argType"+p+".deleteObject(arg"+p+");\n");f.Mf||(x+="    return retType.toWireType(destructors, rv);\n");g.push(x+"};\n");a=wc(g).apply(null,m);g=Zc(a);return ad[b]=g},t:function(a){4<a&&(Ic[a].$e+=1)},D:function(){return kc([])},x:function(a){return kc(Xc(a))},hb:function(){return kc({})},
fb:function(a){var b=Kc(a);sb(b);Jc(a)},A:function(a,b,c){a=Kc(a);b=Kc(b);c=Kc(c);a[b]=c},y:function(a,b){a=Mc(a,"_emval_take_value");a=a.readValueFromPointer(b);return kc(a)},ob:function(){return-52},pb:function(){},a:function(){Na("")},qb:bd,Xc:function(a){W.activeTexture(a)},R:function(a,b){W.attachShader(hd[a],md[b])},S:function(a,b,c){W.bindAttribLocation(hd[a],b,Ta(c))},T:function(a,b){35051==a?W.Xe=b:35052==a&&(W.we=b);W.bindBuffer(a,gd[b])},_b:function(a,b){W.bindFramebuffer(a,jd[b])},$b:function(a,
b){W.bindRenderbuffer(a,kd[b])},Mb:function(a,b){W.bindSampler(a,od[b])},U:function(a,b){W.bindTexture(a,ld[b])},tc:function(a){W.bindVertexArray(nd[a])},wc:function(a){W.bindVertexArray(nd[a])},V:function(a,b,c,f){W.blendColor(a,b,c,f)},W:function(a){W.blendEquation(a)},X:function(a,b){W.blendFunc(a,b)},Tb:function(a,b,c,f,g,m,q,p,x,v){W.blitFramebuffer(a,b,c,f,g,m,q,p,x,v)},Y:function(a,b,c,f){2<=u.version?c&&b?W.bufferData(a,F,f,c,b):W.bufferData(a,b,f):W.bufferData(a,c?F.subarray(c,c+b):b,f)},
Z:function(a,b,c,f){2<=u.version?c&&W.bufferSubData(a,b,F,f,c):W.bufferSubData(a,b,F.subarray(f,f+c))},ac:function(a){return W.checkFramebufferStatus(a)},_:function(a){W.clear(a)},$:function(a,b,c,f){W.clearColor(a,b,c,f)},aa:function(a){W.clearStencil(a)},cb:function(a,b,c,f){return W.clientWaitSync(pd[a],b,(c>>>0)+4294967296*f)},ba:function(a,b,c,f){W.colorMask(!!a,!!b,!!c,!!f)},ca:function(a){W.compileShader(md[a])},da:function(a,b,c,f,g,m,q,p){2<=u.version?W.we||!q?W.compressedTexImage2D(a,b,
c,f,g,m,q,p):W.compressedTexImage2D(a,b,c,f,g,m,F,p,q):W.compressedTexImage2D(a,b,c,f,g,m,p?F.subarray(p,p+q):null)},ea:function(a,b,c,f,g,m,q,p,x){2<=u.version?W.we||!p?W.compressedTexSubImage2D(a,b,c,f,g,m,q,p,x):W.compressedTexSubImage2D(a,b,c,f,g,m,q,F,x,p):W.compressedTexSubImage2D(a,b,c,f,g,m,q,x?F.subarray(x,x+p):null)},fa:function(a,b,c,f,g,m,q,p){W.copyTexSubImage2D(a,b,c,f,g,m,q,p)},ga:function(){var a=ud(hd),b=W.createProgram();b.name=a;b.Qe=b.Oe=b.Pe=0;b.bf=1;hd[a]=b;return a},ha:function(a){var b=
ud(md);md[b]=W.createShader(a);return b},ia:function(a){W.cullFace(a)},ja:function(a,b){for(var c=0;c<a;c++){var f=M[b+4*c>>2],g=gd[f];g&&(W.deleteBuffer(g),g.name=0,gd[f]=null,f==W.Xe&&(W.Xe=0),f==W.we&&(W.we=0))}},bc:function(a,b){for(var c=0;c<a;++c){var f=M[b+4*c>>2],g=jd[f];g&&(W.deleteFramebuffer(g),g.name=0,jd[f]=null)}},ka:function(a){if(a){var b=hd[a];b?(W.deleteProgram(b),b.name=0,hd[a]=null):V(1281)}},cc:function(a,b){for(var c=0;c<a;c++){var f=M[b+4*c>>2],g=kd[f];g&&(W.deleteRenderbuffer(g),
g.name=0,kd[f]=null)}},Nb:function(a,b){for(var c=0;c<a;c++){var f=M[b+4*c>>2],g=od[f];g&&(W.deleteSampler(g),g.name=0,od[f]=null)}},la:function(a){if(a){var b=md[a];b?(W.deleteShader(b),md[a]=null):V(1281)}},Vb:function(a){if(a){var b=pd[a];b?(W.deleteSync(b),b.name=0,pd[a]=null):V(1281)}},ma:function(a,b){for(var c=0;c<a;c++){var f=M[b+4*c>>2],g=ld[f];g&&(W.deleteTexture(g),g.name=0,ld[f]=null)}},uc:function(a,b){for(var c=0;c<a;c++){var f=M[b+4*c>>2];W.deleteVertexArray(nd[f]);nd[f]=null}},xc:function(a,
b){for(var c=0;c<a;c++){var f=M[b+4*c>>2];W.deleteVertexArray(nd[f]);nd[f]=null}},na:function(a){W.depthMask(!!a)},oa:function(a){W.disable(a)},pa:function(a){W.disableVertexAttribArray(a)},qa:function(a,b,c){W.drawArrays(a,b,c)},rc:function(a,b,c,f){W.drawArraysInstanced(a,b,c,f)},pc:function(a,b,c,f,g){W.hf.drawArraysInstancedBaseInstanceWEBGL(a,b,c,f,g)},nc:function(a,b){for(var c=Ad[a],f=0;f<a;f++)c[f]=M[b+4*f>>2];W.drawBuffers(c)},ra:function(a,b,c,f){W.drawElements(a,b,c,f)},sc:function(a,b,
c,f,g){W.drawElementsInstanced(a,b,c,f,g)},qc:function(a,b,c,f,g,m,q){W.hf.drawElementsInstancedBaseVertexBaseInstanceWEBGL(a,b,c,f,g,m,q)},hc:function(a,b,c,f,g,m){W.drawElements(a,f,g,m)},sa:function(a){W.enable(a)},ta:function(a){W.enableVertexAttribArray(a)},Rb:function(a,b){return(a=W.fenceSync(a,b))?(b=ud(pd),a.name=b,pd[b]=a,b):0},ua:function(){W.finish()},va:function(){W.flush()},dc:function(a,b,c,f){W.framebufferRenderbuffer(a,b,c,kd[f])},ec:function(a,b,c,f,g){W.framebufferTexture2D(a,b,
c,ld[f],g)},wa:function(a){W.frontFace(a)},xa:function(a,b){Bd(a,b,"createBuffer",gd)},fc:function(a,b){Bd(a,b,"createFramebuffer",jd)},gc:function(a,b){Bd(a,b,"createRenderbuffer",kd)},Ob:function(a,b){Bd(a,b,"createSampler",od)},ya:function(a,b){Bd(a,b,"createTexture",ld)},vc:function(a,b){Bd(a,b,"createVertexArray",nd)},yc:function(a,b){Bd(a,b,"createVertexArray",nd)},Wb:function(a){W.generateMipmap(a)},za:function(a,b,c){c?M[c>>2]=W.getBufferParameter(a,b):V(1281)},Aa:function(){var a=W.getError()||
td;td=0;return a},Xb:function(a,b,c,f){a=W.getFramebufferAttachmentParameter(a,b,c);if(a instanceof WebGLRenderbuffer||a instanceof WebGLTexture)a=a.name|0;M[f>>2]=a},ab:function(a,b){Cd(a,b)},Ba:function(a,b,c,f){a=W.getProgramInfoLog(hd[a]);null===a&&(a="(unknown error)");b=0<b&&f?la(a,F,f,b):0;c&&(M[c>>2]=b)},Ca:function(a,b,c){if(c)if(a>=fd)V(1281);else if(a=hd[a],35716==b)a=W.getProgramInfoLog(a),null===a&&(a="(unknown error)"),M[c>>2]=a.length+1;else if(35719==b){if(!a.Qe)for(b=0;b<W.getProgramParameter(a,
35718);++b)a.Qe=Math.max(a.Qe,W.getActiveUniform(a,b).name.length+1);M[c>>2]=a.Qe}else if(35722==b){if(!a.Oe)for(b=0;b<W.getProgramParameter(a,35721);++b)a.Oe=Math.max(a.Oe,W.getActiveAttrib(a,b).name.length+1);M[c>>2]=a.Oe}else if(35381==b){if(!a.Pe)for(b=0;b<W.getProgramParameter(a,35382);++b)a.Pe=Math.max(a.Pe,W.getActiveUniformBlockName(a,b).length+1);M[c>>2]=a.Pe}else M[c>>2]=W.getProgramParameter(a,b);else V(1281)},Yb:function(a,b,c){c?M[c>>2]=W.getRenderbufferParameter(a,b):V(1281)},Da:function(a,
b,c,f){a=W.getShaderInfoLog(md[a]);null===a&&(a="(unknown error)");b=0<b&&f?la(a,F,f,b):0;c&&(M[c>>2]=b)},Jb:function(a,b,c,f){a=W.getShaderPrecisionFormat(a,b);M[c>>2]=a.rangeMin;M[c+4>>2]=a.rangeMax;M[f>>2]=a.precision},Ea:function(a,b,c){c?35716==b?(a=W.getShaderInfoLog(md[a]),null===a&&(a="(unknown error)"),M[c>>2]=a?a.length+1:0):35720==b?(a=W.getShaderSource(md[a]),M[c>>2]=a?a.length+1:0):M[c>>2]=W.getShaderParameter(md[a],b):V(1281)},F:function(a){var b=qd[a];if(!b){switch(a){case 7939:b=W.getSupportedExtensions()||
[];b=b.concat(b.map(function(f){return"GL_"+f}));b=Dd(b.join(" "));break;case 7936:case 7937:case 37445:case 37446:(b=W.getParameter(a))||V(1280);b=b&&Dd(b);break;case 7938:b=W.getParameter(7938);b=2<=u.version?"OpenGL ES 3.0 ("+b+")":"OpenGL ES 2.0 ("+b+")";b=Dd(b);break;case 35724:b=W.getParameter(35724);var c=b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);null!==c&&(3==c[1].length&&(c[1]+="0"),b="OpenGL ES GLSL ES "+c[1]+" ("+b+")");b=Dd(b);break;default:V(1280)}qd[a]=b}return b},bb:function(a,
b){if(2>u.version)return V(1282),0;var c=rd[a];if(c)return 0>b||b>=c.length?(V(1281),0):c[b];switch(a){case 7939:return c=W.getSupportedExtensions()||[],c=c.concat(c.map(function(f){return"GL_"+f})),c=c.map(function(f){return Dd(f)}),c=rd[a]=c,0>b||b>=c.length?(V(1281),0):c[b];default:return V(1280),0}},Fa:function(a,b){b=Ta(b);if(a=hd[a]){var c=a,f=c.He,g=c.pf,m;if(!f)for(c.He=f={},c.nf={},m=0;m<W.getProgramParameter(c,35718);++m){var q=W.getActiveUniform(c,m);var p=q.name;q=q.size;var x=Fd(p);x=
0<x?p.slice(0,x):p;var v=c.bf;c.bf+=q;g[x]=[q,v];for(p=0;p<q;++p)f[v]=p,c.nf[v++]=x}c=a.He;f=0;g=b;m=Fd(b);0<m&&(f=parseInt(b.slice(m+1))>>>0,g=b.slice(0,m));if((g=a.pf[g])&&f<g[0]&&(f+=g[1],c[f]=c[f]||W.getUniformLocation(a,b)))return f}else V(1281);return-1},Kb:function(a,b,c){for(var f=Ad[b],g=0;g<b;g++)f[g]=M[c+4*g>>2];W.invalidateFramebuffer(a,f)},Lb:function(a,b,c,f,g,m,q){for(var p=Ad[b],x=0;x<b;x++)p[x]=M[c+4*x>>2];W.invalidateSubFramebuffer(a,p,f,g,m,q)},Sb:function(a){return W.isSync(pd[a])},
Ga:function(a){return(a=ld[a])?W.isTexture(a):0},Ha:function(a){W.lineWidth(a)},Ia:function(a){a=hd[a];W.linkProgram(a);a.He=0;a.pf={}},lc:function(a,b,c,f,g,m){W.lf.multiDrawArraysInstancedBaseInstanceWEBGL(a,M,b>>2,M,c>>2,M,f>>2,Xa,g>>2,m)},mc:function(a,b,c,f,g,m,q,p){W.lf.multiDrawElementsInstancedBaseVertexBaseInstanceWEBGL(a,M,b>>2,c,M,f>>2,M,g>>2,M,m>>2,Xa,q>>2,p)},Ja:function(a,b){3317==a&&(sd=b);W.pixelStorei(a,b)},oc:function(a){W.readBuffer(a)},Ka:function(a,b,c,f,g,m,q){if(2<=u.version)if(W.Xe)W.readPixels(a,
b,c,f,g,m,q);else{var p=Gd(m);W.readPixels(a,b,c,f,g,m,p,q>>31-Math.clz32(p.BYTES_PER_ELEMENT))}else(q=Hd(m,g,c,f,q))?W.readPixels(a,b,c,f,g,m,q):V(1280)},Zb:function(a,b,c,f){W.renderbufferStorage(a,b,c,f)},Ub:function(a,b,c,f,g){W.renderbufferStorageMultisample(a,b,c,f,g)},Pb:function(a,b,c){W.samplerParameteri(od[a],b,c)},Qb:function(a,b,c){W.samplerParameteri(od[a],b,M[c>>2])},La:function(a,b,c,f){W.scissor(a,b,c,f)},Ma:function(a,b,c,f){for(var g="",m=0;m<b;++m){var q=f?M[f+4*m>>2]:-1;g+=Ta(M[c+
4*m>>2],0>q?void 0:q)}W.shaderSource(md[a],g)},Na:function(a,b,c){W.stencilFunc(a,b,c)},Oa:function(a,b,c,f){W.stencilFuncSeparate(a,b,c,f)},Pa:function(a){W.stencilMask(a)},Qa:function(a,b){W.stencilMaskSeparate(a,b)},Ra:function(a,b,c){W.stencilOp(a,b,c)},Sa:function(a,b,c,f){W.stencilOpSeparate(a,b,c,f)},Ta:function(a,b,c,f,g,m,q,p,x){if(2<=u.version)if(W.we)W.texImage2D(a,b,c,f,g,m,q,p,x);else if(x){var v=Gd(p);W.texImage2D(a,b,c,f,g,m,q,p,v,x>>31-Math.clz32(v.BYTES_PER_ELEMENT))}else W.texImage2D(a,
b,c,f,g,m,q,p,null);else W.texImage2D(a,b,c,f,g,m,q,p,x?Hd(p,q,f,g,x):null)},Ua:function(a,b,c){W.texParameterf(a,b,c)},Va:function(a,b,c){W.texParameterf(a,b,S[c>>2])},Wa:function(a,b,c){W.texParameteri(a,b,c)},Ya:function(a,b,c){W.texParameteri(a,b,M[c>>2])},ic:function(a,b,c,f,g){W.texStorage2D(a,b,c,f,g)},Za:function(a,b,c,f,g,m,q,p,x){if(2<=u.version)if(W.we)W.texSubImage2D(a,b,c,f,g,m,q,p,x);else if(x){var v=Gd(p);W.texSubImage2D(a,b,c,f,g,m,q,p,v,x>>31-Math.clz32(v.BYTES_PER_ELEMENT))}else W.texSubImage2D(a,
b,c,f,g,m,q,p,null);else v=null,x&&(v=Hd(p,q,g,m,x)),W.texSubImage2D(a,b,c,f,g,m,q,p,v)},_a:function(a,b){W.uniform1f(Y(a),b)},$a:function(a,b,c){if(2<=u.version)b&&W.uniform1fv(Y(a),S,c>>2,b);else{if(288>=b)for(var f=Id[b-1],g=0;g<b;++g)f[g]=S[c+4*g>>2];else f=S.subarray(c>>2,c+4*b>>2);W.uniform1fv(Y(a),f)}},Tc:function(a,b){W.uniform1i(Y(a),b)},Uc:function(a,b,c){if(2<=u.version)b&&W.uniform1iv(Y(a),M,c>>2,b);else{if(288>=b)for(var f=Jd[b-1],g=0;g<b;++g)f[g]=M[c+4*g>>2];else f=M.subarray(c>>2,c+
4*b>>2);W.uniform1iv(Y(a),f)}},Vc:function(a,b,c){W.uniform2f(Y(a),b,c)},Wc:function(a,b,c){if(2<=u.version)b&&W.uniform2fv(Y(a),S,c>>2,2*b);else{if(144>=b)for(var f=Id[2*b-1],g=0;g<2*b;g+=2)f[g]=S[c+4*g>>2],f[g+1]=S[c+(4*g+4)>>2];else f=S.subarray(c>>2,c+8*b>>2);W.uniform2fv(Y(a),f)}},Sc:function(a,b,c){W.uniform2i(Y(a),b,c)},Rc:function(a,b,c){if(2<=u.version)b&&W.uniform2iv(Y(a),M,c>>2,2*b);else{if(144>=b)for(var f=Jd[2*b-1],g=0;g<2*b;g+=2)f[g]=M[c+4*g>>2],f[g+1]=M[c+(4*g+4)>>2];else f=M.subarray(c>>
2,c+8*b>>2);W.uniform2iv(Y(a),f)}},Qc:function(a,b,c,f){W.uniform3f(Y(a),b,c,f)},Pc:function(a,b,c){if(2<=u.version)b&&W.uniform3fv(Y(a),S,c>>2,3*b);else{if(96>=b)for(var f=Id[3*b-1],g=0;g<3*b;g+=3)f[g]=S[c+4*g>>2],f[g+1]=S[c+(4*g+4)>>2],f[g+2]=S[c+(4*g+8)>>2];else f=S.subarray(c>>2,c+12*b>>2);W.uniform3fv(Y(a),f)}},Oc:function(a,b,c,f){W.uniform3i(Y(a),b,c,f)},Nc:function(a,b,c){if(2<=u.version)b&&W.uniform3iv(Y(a),M,c>>2,3*b);else{if(96>=b)for(var f=Jd[3*b-1],g=0;g<3*b;g+=3)f[g]=M[c+4*g>>2],f[g+
1]=M[c+(4*g+4)>>2],f[g+2]=M[c+(4*g+8)>>2];else f=M.subarray(c>>2,c+12*b>>2);W.uniform3iv(Y(a),f)}},Mc:function(a,b,c,f,g){W.uniform4f(Y(a),b,c,f,g)},Lc:function(a,b,c){if(2<=u.version)b&&W.uniform4fv(Y(a),S,c>>2,4*b);else{if(72>=b){var f=Id[4*b-1],g=S;c>>=2;for(var m=0;m<4*b;m+=4){var q=c+m;f[m]=g[q];f[m+1]=g[q+1];f[m+2]=g[q+2];f[m+3]=g[q+3]}}else f=S.subarray(c>>2,c+16*b>>2);W.uniform4fv(Y(a),f)}},zc:function(a,b,c,f,g){W.uniform4i(Y(a),b,c,f,g)},Ac:function(a,b,c){if(2<=u.version)b&&W.uniform4iv(Y(a),
M,c>>2,4*b);else{if(72>=b)for(var f=Jd[4*b-1],g=0;g<4*b;g+=4)f[g]=M[c+4*g>>2],f[g+1]=M[c+(4*g+4)>>2],f[g+2]=M[c+(4*g+8)>>2],f[g+3]=M[c+(4*g+12)>>2];else f=M.subarray(c>>2,c+16*b>>2);W.uniform4iv(Y(a),f)}},Bc:function(a,b,c,f){if(2<=u.version)b&&W.uniformMatrix2fv(Y(a),!!c,S,f>>2,4*b);else{if(72>=b)for(var g=Id[4*b-1],m=0;m<4*b;m+=4)g[m]=S[f+4*m>>2],g[m+1]=S[f+(4*m+4)>>2],g[m+2]=S[f+(4*m+8)>>2],g[m+3]=S[f+(4*m+12)>>2];else g=S.subarray(f>>2,f+16*b>>2);W.uniformMatrix2fv(Y(a),!!c,g)}},Cc:function(a,
b,c,f){if(2<=u.version)b&&W.uniformMatrix3fv(Y(a),!!c,S,f>>2,9*b);else{if(32>=b)for(var g=Id[9*b-1],m=0;m<9*b;m+=9)g[m]=S[f+4*m>>2],g[m+1]=S[f+(4*m+4)>>2],g[m+2]=S[f+(4*m+8)>>2],g[m+3]=S[f+(4*m+12)>>2],g[m+4]=S[f+(4*m+16)>>2],g[m+5]=S[f+(4*m+20)>>2],g[m+6]=S[f+(4*m+24)>>2],g[m+7]=S[f+(4*m+28)>>2],g[m+8]=S[f+(4*m+32)>>2];else g=S.subarray(f>>2,f+36*b>>2);W.uniformMatrix3fv(Y(a),!!c,g)}},Dc:function(a,b,c,f){if(2<=u.version)b&&W.uniformMatrix4fv(Y(a),!!c,S,f>>2,16*b);else{if(18>=b){var g=Id[16*b-1],
m=S;f>>=2;for(var q=0;q<16*b;q+=16){var p=f+q;g[q]=m[p];g[q+1]=m[p+1];g[q+2]=m[p+2];g[q+3]=m[p+3];g[q+4]=m[p+4];g[q+5]=m[p+5];g[q+6]=m[p+6];g[q+7]=m[p+7];g[q+8]=m[p+8];g[q+9]=m[p+9];g[q+10]=m[p+10];g[q+11]=m[p+11];g[q+12]=m[p+12];g[q+13]=m[p+13];g[q+14]=m[p+14];g[q+15]=m[p+15]}}else g=S.subarray(f>>2,f+64*b>>2);W.uniformMatrix4fv(Y(a),!!c,g)}},Ec:function(a){a=hd[a];W.useProgram(a);W.xf=a},Fc:function(a,b){W.vertexAttrib1f(a,b)},Gc:function(a,b){W.vertexAttrib2f(a,S[b>>2],S[b+4>>2])},Hc:function(a,
b){W.vertexAttrib3f(a,S[b>>2],S[b+4>>2],S[b+8>>2])},Ic:function(a,b){W.vertexAttrib4f(a,S[b>>2],S[b+4>>2],S[b+8>>2],S[b+12>>2])},jc:function(a,b){W.vertexAttribDivisor(a,b)},kc:function(a,b,c,f,g){W.vertexAttribIPointer(a,b,c,f,g)},Jc:function(a,b,c,f,g,m){W.vertexAttribPointer(a,b,c,!!f,g,m)},Kc:function(a,b,c,f){W.viewport(a,b,c,f)},db:function(a,b,c,f){W.waitSync(pd[a],b,(c>>>0)+4294967296*f)},nb:function(a){var b=F.length;a>>>=0;if(2147483648<a)return!1;for(var c=1;4>=c;c*=2){var f=b*(1+.2/c);
f=Math.min(f,a+100663296);var g=Math,m=g.min;f=Math.max(a,f);f+=(65536-f%65536)%65536;a:{var q=Oa.buffer;try{Oa.grow(m.call(g,2147483648,f)-q.byteLength+65535>>>16);Za();var p=1;break a}catch(x){}p=void 0}if(p)return!0}return!1},Yc:function(){return u?u.Jf:0},Q:function(a){return ia(a)?0:-5},sb:function(a,b){var c=0;Ld().forEach(function(f,g){var m=b+c;g=Xa[a+4*g>>2]=m;for(m=0;m<f.length;++m)Ua[g++>>0]=f.charCodeAt(m);Ua[g>>0]=0;c+=f.length+1});return 0},tb:function(a,b){var c=Ld();Xa[a>>2]=c.length;
var f=0;c.forEach(function(g){f+=g.length+1});Xa[b>>2]=f;return 0},Eb:function(a){if(!noExitRuntime){if(t.onExit)t.onExit(a);Pa=!0}sa(a,new Fa(a))},I:function(){return 52},ib:function(){return 52},yb:function(){return 52},jb:function(){return 70},G:function(a,b,c,f){for(var g=0,m=0;m<c;m++){var q=Xa[b>>2],p=Xa[b+4>>2];b+=8;for(var x=0;x<p;x++){var v=F[q+x],G=Nd[a];0===v||10===v?((1===a?La:Ga)(Sa(G,0)),G.length=0):G.push(v)}g+=p}Xa[f>>2]=g;return 0},Zc:function(a,b){W.bindFramebuffer(a,jd[b])},Xa:function(a){W.clear(a)},
wb:function(a,b,c,f){W.clearColor(a,b,c,f)},eb:function(a){W.clearStencil(a)},E:function(a,b){Cd(a,b)},f:Yd,j:Zd,d:$d,z:ae,Ib:be,N:ce,M:de,C:ee,h:fe,m:ge,e:he,l:ie,Hb:je,Fb:ke,Gb:le,lb:function(a,b,c,f){return Sd(a,b,c,f)}};
(function(){function a(g){t.asm=g.exports;Oa=t.asm._c;Za();$a=t.asm.ad;bb.unshift(t.asm.$c);ib--;t.monitorRunDependencies&&t.monitorRunDependencies(ib);0==ib&&(null!==jb&&(clearInterval(jb),jb=null),kb&&(g=kb,kb=null,g()))}function b(g){a(g.instance)}function c(g){return pb().then(function(m){return WebAssembly.instantiate(m,f)}).then(function(m){return m}).then(g,function(m){Ga("failed to asynchronously prepare wasm: "+m);Na(m)})}var f={a:me};ib++;t.monitorRunDependencies&&t.monitorRunDependencies(ib);
if(t.instantiateWasm)try{return t.instantiateWasm(f,a)}catch(g){Ga("Module.instantiateWasm callback failed with error: "+g),ba(g)}(function(){return Ma||"function"!=typeof WebAssembly.instantiateStreaming||lb()||mb.startsWith("file://")||va||"function"!=typeof fetch?c(b):fetch(mb,{credentials:"same-origin"}).then(function(g){return WebAssembly.instantiateStreaming(g,f).then(b,function(m){Ga("wasm streaming compile failed: "+m);Ga("falling back to ArrayBuffer instantiation");return c(b)})})})().catch(ba);
return{}})();var uc=t._free=function(){return(uc=t._free=t.asm.bd).apply(null,arguments)},Ed=t._malloc=function(){return(Ed=t._malloc=t.asm.cd).apply(null,arguments)},tc=t.___getTypeName=function(){return(tc=t.___getTypeName=t.asm.dd).apply(null,arguments)};t.__embind_initialize_bindings=function(){return(t.__embind_initialize_bindings=t.asm.ed).apply(null,arguments)};function ne(){return(ne=t.asm.fd).apply(null,arguments)}function oe(){return(oe=t.asm.gd).apply(null,arguments)}
function pe(){return(pe=t.asm.hd).apply(null,arguments)}t.dynCall_viji=function(){return(t.dynCall_viji=t.asm.id).apply(null,arguments)};t.dynCall_vijiii=function(){return(t.dynCall_vijiii=t.asm.jd).apply(null,arguments)};t.dynCall_viiiiij=function(){return(t.dynCall_viiiiij=t.asm.kd).apply(null,arguments)};t.dynCall_ji=function(){return(t.dynCall_ji=t.asm.ld).apply(null,arguments)};t.dynCall_jii=function(){return(t.dynCall_jii=t.asm.md).apply(null,arguments)};
t.dynCall_iiij=function(){return(t.dynCall_iiij=t.asm.nd).apply(null,arguments)};t.dynCall_iiiij=function(){return(t.dynCall_iiiij=t.asm.od).apply(null,arguments)};t.dynCall_viij=function(){return(t.dynCall_viij=t.asm.pd).apply(null,arguments)};t.dynCall_viiij=function(){return(t.dynCall_viiij=t.asm.qd).apply(null,arguments)};t.dynCall_iij=function(){return(t.dynCall_iij=t.asm.rd).apply(null,arguments)};t.dynCall_vij=function(){return(t.dynCall_vij=t.asm.sd).apply(null,arguments)};
t.dynCall_jiiii=function(){return(t.dynCall_jiiii=t.asm.td).apply(null,arguments)};t.dynCall_jiiiiii=function(){return(t.dynCall_jiiiiii=t.asm.ud).apply(null,arguments)};t.dynCall_jiiiiji=function(){return(t.dynCall_jiiiiji=t.asm.vd).apply(null,arguments)};t.dynCall_iijj=function(){return(t.dynCall_iijj=t.asm.wd).apply(null,arguments)};t.dynCall_jiji=function(){return(t.dynCall_jiji=t.asm.xd).apply(null,arguments)};t.dynCall_viijii=function(){return(t.dynCall_viijii=t.asm.yd).apply(null,arguments)};
t.dynCall_iiiiij=function(){return(t.dynCall_iiiiij=t.asm.zd).apply(null,arguments)};t.dynCall_iiiiijj=function(){return(t.dynCall_iiiiijj=t.asm.Ad).apply(null,arguments)};t.dynCall_iiiiiijj=function(){return(t.dynCall_iiiiiijj=t.asm.Bd).apply(null,arguments)};function Yd(a,b){var c=oe();try{return oc(a)(b)}catch(f){pe(c);if(f!==f+0)throw f;ne(1,0)}}function Zd(a,b,c){var f=oe();try{return oc(a)(b,c)}catch(g){pe(f);if(g!==g+0)throw g;ne(1,0)}}
function he(a,b,c,f){var g=oe();try{oc(a)(b,c,f)}catch(m){pe(g);if(m!==m+0)throw m;ne(1,0)}}function $d(a,b,c,f){var g=oe();try{return oc(a)(b,c,f)}catch(m){pe(g);if(m!==m+0)throw m;ne(1,0)}}function fe(a,b){var c=oe();try{oc(a)(b)}catch(f){pe(c);if(f!==f+0)throw f;ne(1,0)}}function ge(a,b,c){var f=oe();try{oc(a)(b,c)}catch(g){pe(f);if(g!==g+0)throw g;ne(1,0)}}function be(a,b,c,f,g,m){var q=oe();try{return oc(a)(b,c,f,g,m)}catch(p){pe(q);if(p!==p+0)throw p;ne(1,0)}}
function ie(a,b,c,f,g){var m=oe();try{oc(a)(b,c,f,g)}catch(q){pe(m);if(q!==q+0)throw q;ne(1,0)}}function ce(a,b,c,f,g,m,q){var p=oe();try{return oc(a)(b,c,f,g,m,q)}catch(x){pe(p);if(x!==x+0)throw x;ne(1,0)}}function ae(a,b,c,f,g){var m=oe();try{return oc(a)(b,c,f,g)}catch(q){pe(m);if(q!==q+0)throw q;ne(1,0)}}function je(a,b,c,f,g,m){var q=oe();try{oc(a)(b,c,f,g,m)}catch(p){pe(q);if(p!==p+0)throw p;ne(1,0)}}
function le(a,b,c,f,g,m,q,p,x,v){var G=oe();try{oc(a)(b,c,f,g,m,q,p,x,v)}catch(J){pe(G);if(J!==J+0)throw J;ne(1,0)}}function ee(a){var b=oe();try{oc(a)()}catch(c){pe(b);if(c!==c+0)throw c;ne(1,0)}}function ke(a,b,c,f,g,m,q){var p=oe();try{oc(a)(b,c,f,g,m,q)}catch(x){pe(p);if(x!==x+0)throw x;ne(1,0)}}function de(a,b,c,f,g,m,q,p,x,v){var G=oe();try{return oc(a)(b,c,f,g,m,q,p,x,v)}catch(J){pe(G);if(J!==J+0)throw J;ne(1,0)}}var qe;kb=function re(){qe||se();qe||(kb=re)};
function se(){function a(){if(!qe&&(qe=!0,t.calledRun=!0,!Pa)){qb(bb);aa(t);if(t.onRuntimeInitialized)t.onRuntimeInitialized();if(t.postRun)for("function"==typeof t.postRun&&(t.postRun=[t.postRun]);t.postRun.length;){var b=t.postRun.shift();cb.unshift(b)}qb(cb)}}if(!(0<ib)){if(t.preRun)for("function"==typeof t.preRun&&(t.preRun=[t.preRun]);t.preRun.length;)hb();qb(ab);0<ib||(t.setStatus?(t.setStatus("Running..."),setTimeout(function(){setTimeout(function(){t.setStatus("")},1);a()},1)):a())}}
if(t.preInit)for("function"==typeof t.preInit&&(t.preInit=[t.preInit]);0<t.preInit.length;)t.preInit.pop()();se();


  return CanvasKitInit.ready
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = CanvasKitInit;
else if (typeof define === 'function' && define['amd'])
  define([], function() { return CanvasKitInit; });
else if (typeof exports === 'object')
  exports["CanvasKitInit"] = CanvasKitInit;
